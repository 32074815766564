import { connect } from 'react-redux'

import {
  activateInlineField,
  deactivateInlineField,
} from 'src/service-design/shared/ui/InlineField/actions/inlineFields'

import { InlineField as InlineFieldComp } from './component'

const getInlineFormName = (state: any) => state.ui.inlineFields.activeField

type OwnProps = {
  field: string
  onSubmit: (...args: any[]) => void
}
const mapStateToProps = (state: any, { field }: OwnProps) => ({
  active: field === getInlineFormName(state),
})

const mapDispatchToProps = (dispatch: any, { field }: OwnProps) => ({
  activateField: () => dispatch(activateInlineField(field)),
  deactivateField: () => dispatch(deactivateInlineField()),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: OwnProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (...args: any[]) => {
    dispatchProps.deactivateField()
    ownProps.onSubmit(...args)
  },
})

export const InlineField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(InlineFieldComp)
