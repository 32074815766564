import { Duration } from './duration'
import { EpochTime } from './epoch-time'

export class Interval {
  constructor(
    public readonly start: EpochTime,
    public readonly end: EpochTime,
  ) {}

  /**
   * Constructs a `Interval` from `EpochTimes`
   *
   * @param start Start of the interval
   * @param end End of the interval
   */
  static fromEpochTimes(start: EpochTime, end: EpochTime) {
    return new Interval(start, end)
  }

  /**
   * Returns the Duration between the start and end
   */
  duration(): Duration {
    return Duration.fromSeconds(this.end.toSeconds() - this.start.toSeconds())
  }

  /**
   * Return a EpochTime representing the mid-point between `start` and `end`
   */
  midPoint(): EpochTime {
    return this.start.makeLater(this.duration().divide(2))
  }
}
