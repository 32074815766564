import 'semantic-ui-css/semantic.min.css'

import { Settings } from 'luxon'
import React from 'react'
import ReactDOM from 'react-dom'

import { initAuth } from 'src/core/auth'
import { stayLoggedIn } from 'src/service-design/shared/users/actions'
import 'src/service-design/shared/monkeyPatches'

import App from './App'

const rootEl = document.getElementById('root')

ReactDOM.render(React.createElement(App), rootEl)

// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line no-console
  console.log('Application loading')
}

// HACK: setting up poll to maintian session with keycloak security proxy.
stayLoggedIn()
initAuth()

// Ensure luxon never gives us DateTime or Duration objects that are invalid
Settings.throwOnInvalid = true
