import React from 'react'
import { Popup as SemanticPopup, PopupProps } from 'semantic-ui-react'

const MODIFIERS = [
  {
    name: 'preventOverflow',
    enabled: true,
  },
]

const Popup = (props: PopupProps) => (
  <SemanticPopup popperModifiers={MODIFIERS} {...props} />
)
Popup.Header = SemanticPopup.Header
Popup.Content = SemanticPopup.Content

export { Popup }
