import {
  SECONDS_PER_DAY,
  SECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
} from 'src/service-design/core/domain/constants'

import { EpochTime } from './epoch-time'
import { TZTime } from './tz-time'

import { secondsToTimeOfDay } from '.'

export class TimeOfDay {
  constructor(private readonly _seconds: number) {
    if (_seconds > SECONDS_PER_DAY) {
      throw new Error('Provided value is greater than one day')
    }
  }

  /**
   *  Constructs a `TimeOfDay` from a seconds representation
   */
  static fromSeconds(seconds: number) {
    return new TimeOfDay(seconds)
  }

  /**
   *  Constructs a `TimeOfDay` from a EpochTime
   */
  static fromEpochTime(epochTime: EpochTime) {
    return new TimeOfDay(secondsToTimeOfDay(epochTime.toSeconds()).time)
  }

  /**
   * Constructs a `TimeOfDay` from a TZTime, including it's tzoffset (ie will be
   * considered a local time)
   */
  static fromTZTime(tzTime: TZTime) {
    return new TimeOfDay(secondsToTimeOfDay(tzTime.toSeconds()).time)
  }

  /**
   * From the underlying seconds since midnight, computes
   * 1. hours since midnight
   * 2. minutes since start of hour
   *
   * @returns {[number, number]} [hoursSinceMidnight, minutesSinceStartOfHour]
   */
  get _hoursMinsComponents(): [number, number] {
    const seconds = this._seconds

    const s = seconds % SECONDS_PER_MINUTE

    const minutes = (seconds - s) / SECONDS_PER_MINUTE
    const m = minutes % MINUTES_PER_HOUR

    const hours = (minutes - m) / MINUTES_PER_HOUR
    return [hours, m]
  }

  /**
   * @returns Number of hours since the start of the day
   */
  get hourOfDay(): number {
    return this._hoursMinsComponents[0]
  }

  /**
   * @returns Number of minutes since the start of the hour
   */
  get minutesOfHour(): number {
    return this._hoursMinsComponents[1]
  }

  /**
   * Checks if this `TimeOfDay` and another have the same underlying representation
   * @param  other the other TimeOfDay
   *
   * @returns whether this and other are equal
   */
  equals(other: TimeOfDay): boolean {
    return this._seconds === other._seconds
  }

  /**
   * Provides a escape hatch to get the underlying data
   */
  toSeconds() {
    return this._seconds
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf() {
    throw new Error('TimeOfDay can not be used with native arithmetic')
  }
}
