import { set } from 'lodash'

import { ENVIRONMENT } from 'src/core/constants'

import { toLocalValue } from 'src/service-design/shared/utils/flags'

// TODO: Train templates flag is now a standard feature.
// The flag and all code controlled by the flag should be excised together.

const FEATURE_FLAG_NAMES = [
  ['languageChange', 'REACT_APP_LANG_CHANGE'],
  ['defaultLanguage', 'REACT_APP_LANG_DEFAULT'],
  ['languageOptions.en', 'REACT_APP_LANG_EN'],
  ['languageOptions.en-AU', 'REACT_APP_LANG_EN_AU'],
  ['languageOptions.es-MX', 'REACT_APP_LANG_ES_MX'],
  ['blocks', 'REACT_APP_BLOCKS'],
  ['crewing', 'REACT_APP_CREWING'],
  ['auditLog', 'REACT_APP_AUDIT_LOG'],
  ['freightServices', 'REACT_APP_FREIGHT_SERVICES'],
  ['dummyApiEndpoints', 'REACT_APP_DUMMY_API_ENDPOINTS'],
  ['remoteRests', 'REACT_APP_REMOTE_RESTS'],
  ['returnEmpties', 'REACT_APP_RETURN_EMPTIES'],
  ['shiftGanttD3Interactions', 'REACT_APP_D3_SHIFT_GANTT'],
  ['graphOverview', 'REACT_APP_GRAPH_OVERVIEW'],
]

const envFlags = {}
for (const [key, envKey] of FEATURE_FLAG_NAMES) {
  const val = process.env[envKey]
  if (val !== undefined) {
    if (val === '0' || val === '1') {
      set(envFlags, key, val === '1')
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Value ${val} is not valid for env ${envKey}`)
    }
  }
}

let targetEnvFlag = process.env.REACT_APP_TARGET_ENV
if (process.env.NODE_ENV === 'development') {
  let localEnv
  try {
    localEnv = window.localStorage.getItem('TARGET_ENV')
  } catch {
    // in a worker where we can't access localstorage, default back to .env
  }

  if (localEnv) {
    targetEnvFlag = localEnv
  }
}

const defaultFlags = {
  languageChange: true,
  defaultLanguage: false,
  languageOptions: {
    en: false,
    'en-AU': true,
    'es-MX': false,
  },
  auditLog: true,
  dummyApiEndpoints: process.env.NODE_ENV === 'development',
  remoteRests: false,

  freightServices: targetEnvFlag === 'TR' || targetEnvFlag === 'PN',
  blocks: targetEnvFlag === 'KCS',
  crewing: targetEnvFlag === 'TR',
  fleetPlanExport: targetEnvFlag === 'KCS',

  // Following are service-design flags dependent on targetEnv:
  trainGraph: targetEnvFlag === 'TR' || targetEnvFlag === 'PN',
  wagonFleet: targetEnvFlag === 'TR' || targetEnvFlag === 'PN',
  // TODO: Consider removing after service design entrypoints are finished
  emptyWagons: targetEnvFlag === 'TR' || targetEnvFlag === 'PN',
  extraTonnage: targetEnvFlag === 'KCS',
  returnEmpties: false,
  shiftGanttD3Interactions: false,
  graphOverview: false,
} as const

const engineAliases = {
  ceto: process.env.REACT_APP_CETO_ALIAS || 'ceto',
  pallas: process.env.REACT_APP_PALLAS_ALIAS || 'pallas',
  swiss: process.env.REACT_APP_SWISS_ALIAS || 'swiss',
  pluto: process.env.REACT_APP_PLUTO_ALIAS || 'pluto',
  sedna: process.env.REACT_APP_SEDNA_ALIAS || 'sedna',
} as const

const engineFlags = {
  engineSwiss: true,
  engineCeto: targetEnvFlag === 'TR',
  engineSedna: targetEnvFlag === 'TR',
  enginePallas: targetEnvFlag === 'TR',
  enginePluto: targetEnvFlag === 'TR',
  engineSwissReview: targetEnvFlag === 'KCS' || targetEnvFlag === 'PN',
} as const

const flags = {
  ...defaultFlags,
  ...envFlags, // Apply overrides if set
  ...engineFlags,
  conflicts: targetEnvFlag === 'TR',
  engineAliases,
} as const

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  Object.keys(flags).forEach(flag => {
    const localValue = localStorage.getItem(flag)
    if (['true', 'false'].includes(localValue)) {
      // @ts-ignore mutate the flags in local development
      flags[flag] = toLocalValue(localValue as 'true' | 'false')
    }
  })
}

/**
 * Dynamically checks if a feature flag is set using a url query parameter
 * You can set a feature flag by adding `?<flagName>=<1/0>` to the URL
 */
if (ENVIRONMENT !== 'prod' && typeof window !== 'undefined') {
  const flagKeys = (Object.keys(flags) as (keyof typeof flags)[]).filter(
    flagName => typeof flags[flagName] == 'boolean',
  )
  const urlParams = new URLSearchParams(window.location.search)
  for (const flagName of flagKeys) {
    const val = urlParams.get(flagName)
    if (val) {
      if (val === '1') {
        // @ts-ignore
        flags[flagName] = true
      } else if (val === '0') {
        // @ts-ignore
        flags[flagName] = false
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Unknown value '${val}' for flag '${flagName}'`)
      }
    }
  }
}

export { flags }
