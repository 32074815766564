import { maxBy, sumBy } from 'lodash'

export class Duration {
  /**
   * Constant `Duration` with no offset
   */
  static readonly nil = new Duration(0)

  constructor(private readonly _seconds: number) {}

  /**
   *  Constructs a `Duration` from a seconds representation
   */
  static fromSeconds(seconds: number) {
    return new Duration(seconds)
  }

  /**
   * Returns the longest `Duration` provided a number of `Duration` arguments
   */
  static longest(...durations: Duration[]): Duration {
    return maxBy(durations, x => x._seconds)
  }

  /**
   *
   * Returns the total sum of the provided `Duration` arguments
   */
  static sum(...durations: Duration[]): Duration {
    return Duration.fromSeconds(sumBy(durations, x => x._seconds))
  }

  /**
   * Multiplies the internal representation of this `Duration` by the given factor
   *
   * @param  factor
   *
   * @returns a new `Duration` with the new value
   */
  multiply(factor: number) {
    return new Duration(this._seconds * factor)
  }

  /**
   * Multiplies the internal representation of this `Duration` by the given factor
   *
   * @param  factor
   *
   * @returns a new `Duration` with the new value
   */

  divide(factor: number) {
    return new Duration(this._seconds / factor)
  }

  /**
   * Adds the other `Duration` from this `Duration` returning a new `Duration`
   *
   * @param  toAdd the other `Duration
   *
   * @returns a new `Duration` with the new value
   */
  add(toAdd: Duration): Duration {
    return new Duration(this._seconds + toAdd._seconds)
  }

  /**
   * Subtracts the other `Duration` from this `Duration` returning a new `Duration`
   *
   * @param  toSubtract the other `Duration
   *
   * @returns a new `Duration` with the new value
   */
  subtract(toSubtract: Duration): Duration {
    return new Duration(this._seconds - toSubtract._seconds)
  }

  /**
   * Checks if this `Duration` and another have the same underlying representation
   * @param  other the other duration
   *
   * @returns whether this and other are equal
   */
  equals(other: Duration): boolean {
    return this._seconds === other._seconds
  }

  /**
   * Returns if this Duration is shorter than the `other` Duration
   *
   * If they are equal the result is true
   * @param other another Duration
   *
   * @returns whether this is shorter than other
   */

  isShorter(other: Duration): boolean {
    return this._seconds <= other._seconds
  }

  /**
   * Returns if this Duration is shorter than the `other` Duration
   *
   * If they are equal the result is false
   * @param other another Duration
   *
   * @returns whether this is shorter than other
   */

  isStrictlyShorter(other: Duration): boolean {
    return this._seconds < other._seconds
  }

  /**
   * Returns if this Duration is longer than the `other` Duration
   *
   * If they are equal the result is true
   * @param other another Duration
   *
   * @returns whether this is longer than other
   */

  isLonger(other: Duration): boolean {
    return this._seconds >= other._seconds
  }

  /**
   * Returns if this Duration is longer than the `other` Duration
   *
   * If they are equal the result is false
   * @param other another Duration
   *
   * @returns whether this is longer than other
   */

  isStrictlyLonger(other: Duration): boolean {
    return this._seconds > other._seconds
  }

  /**
   * Provides a escape hatch to get the underlying data
   */
  toSeconds() {
    return this._seconds
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf() {
    throw new Error('Duration can not be used with native arithmetic')
  }
}
