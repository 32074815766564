import { getAuthToken } from 'src/core/auth'

const STAY_LOGGED_IN_INTERVAL = 60 * 1000

// HACK: Keycloak Security Proxy sessions time out after 30 mins of inactivity
// so this action calls the ping url every minute.

export const stayLoggedIn = () => {
  setInterval(async () => {
    const headers: { Authorization?: string } = {}
    const token = await getAuthToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    fetch('/session-keep-alive', {
      method: 'GET',
      headers,
    })
  }, STAY_LOGGED_IN_INTERVAL)
}
