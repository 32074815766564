export default {
  common: {
    'Errors occurred which were automatically fixed':
      'Errors occurred which were automatically fixed',
    'Document Properties...': 'Document Properties...',
    'Document Properties': 'Document Properties',
    'Must be within the week': 'Must be within the week',
    'Must be within two weeks': 'Must be within two weeks',
    '[ERR:DEFAULT_SAVE]':
      'Please check your internet connection or contact your Biarri representative to diagnose. If you continue your changes will not persist. Use the retry button at the bottom of the screen to re-start saving.',
    '[ERR:CONNECTION_ERROR]':
      'You appear to be offline. Please check you internet connection. Use the retry button at the bottom of the screen to re-start saving.',
    '[ERR:REVISION_OUT_OF_DATE]':
      'This document is out of date. You will be able to make edits but your changes will not be persisted.',
    '[ERR:CONNECTION_ERROR_FAID]':
      'You appear to be offline. Please reinstate your network connection and refresh the page.',
    Logout: 'Logout',
    Name: 'Name',
    Rename: 'Rename',
    'Open Copy': 'Open Copy', // As in open a new copy of the current document
    Archive: 'Archive',
    Unarchive: 'Unarchive',
    Copy: 'Copy',
    'Copy to...': 'Copy to...',
    Close: 'Close',
    Cancel: 'Cancel',
    Caution: 'Caution',
    Open: 'Open',
    'Audit Log': 'Audit Log',
    Confirm: 'Confirm',
    Acknowledge: 'Acknowledge',
    Back: 'Back',
    Via: 'Via',
    'Schedule Importer': 'Schedule Importer',
    'Rename document': 'Rename document',
    'Archive document': 'Archive document',
    'Unarchive document': 'Unarchive document',
    Required: 'Required',
    'Must be a number': 'Must be a number',
    'Import {{type}} from Excel': 'Import {{type}} from Excel',
    'Must be an integer': 'Must be an integer',
    'Should be within the week': 'Should be within the week',
    'Must be positive': 'Must be positive', // As in a number must be positive
    'Cannot be negative': 'Cannot be negative',
    'Should be between 0 and 100': 'Should be between 0 and 100',
    'String cannot start with empty space and needs more than one character.':
      'String cannot start with empty space and needs more than one character.',
    "The page you're looking for can't be found":
      "The page you're looking for can't be found.",
    'Cost Summary': 'Cost Summary',
    'All changes saved': 'All changes saved',
    'Saving...': 'Saving...',
    'Save Error! Click to retry': 'Save Error! Click to retry',
    'Pending Save...': 'Pending Save...',
    'You have {{count}} warning': 'You have {{count}} warning',
    'You have {{count}} warning_plural': 'You have {{count}} warnings',
    'in {{count}} category': 'in {{count}} category',
    'in {{count}} category_plural': 'in {{count}} categories',
    'There are unsaved changes. Are you sure you want to leave?':
      'There are unsaved changes. Are you sure you want to leave?',
    'Changes are currently being saved. Are you sure you want to leave before it has finished?':
      'Changes are currently being saved. Are you sure you want to leave before it has finished?',
    'There was an error loading the document.':
      'There was an error loading the document.',
    'There was an error saving the document.':
      'There was an error saving the document.',
    'Unable to calculate the FAID score.':
      'Unable to calculate the FAID score.',
    'Document is invalid.': 'Document is invalid.',
    'There was an error loading the current user.':
      'There was an error loading the current user.',
    'Server error': 'Server error',
    'The file could not be imported': 'The file could not be imported',
    'File read error': 'File read error',
    'There was an unexpected error while saving the document':
      'There was an unexpected error while saving the document',
    'Failed to update document.': 'Failed to update document.',
    'Status Code': 'Status Code',
    'Import completed successfully! You can access the new document':
      'Import completed successfully! You can access the new document',
    '{{actionName}}': '{{actionName}} ',
    here: 'here',
    Add: 'Add',
    Prepend: 'Prepend',
    Edit: 'Edit',
    Update: 'Update',
    Delete: 'Delete',
    delete: 'delete',
    Upload: 'Upload',
    Import: 'Import',
    // action should be one of 'Add', 'Edit', 'Update', 'Prepend'
    '{{action}} {{entity}}': '{{action}} {{entity}}',
    Access: 'Access',
    'Access - Base': 'Access - Base',
    Tonnage: 'Tonnage',
    Locomotives: 'Locomotives',
    Wagons: 'Wagons',
    'Working km': 'Working km',
    'Working hour': 'Working hour',
    'Fuel mainline': 'Fuel mainline',
    'Fuel local work': 'Fuel local work',
    'Wagon km': 'Wagon km',
    'Import log': 'Import log',
    'Choose File...': 'Choose File...',
    'No file chosen': 'No file chosen',
    'Access - Train Weight-distance': 'Access - Train Weight-distance',
    'Locomotives - Fleet': 'Locomotives - Fleet',
    'Wagons - Fleet': 'Wagons - Fleet',
    'LVs - Fleet': 'LVs - Fleet',
    'LVs - Distance': 'LVs - Distance',
    'LVs - Fuel': 'LVs - Fuel',
    'Locomotives - Mainline Working Distance':
      'Locomotives - Mainline Working Distance',
    'Locomotives - Yard Working Time': 'Locomotives - Yard Working Time',
    'Locomotives - Mainline Working Fuel':
      'Locomotives - Mainline Working Fuel',
    'Locomotives - Yard Working Fuel': 'Locomotives - Yard Working Fuel',
    'Wagons - Distance': 'Wagons - Distance',
    Export: 'Export',
    Sun: 'Sun',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Roster: 'Roster',
    'LV - Fixed Costs': 'LV - Fixed Costs',
    'LV - Km': 'LV - Km',
    'LV - Fuel': 'LV - Fuel',
    'Crew - Productive': 'Crew - Productive',
    'Crew - Non-productive': 'Crew - Non-productive',
    'Crew - Undertime': 'Crew - Undertime',
    'Crew - Relief': 'Crew - Relief',
    Save: 'Save',
    'updating...': 'updating...',
    'Affects {{collectionName}} entities with ids:':
      'Affects {{collectionName}} entities with ids:',
    'Entity from {{sourceMissingName}} with id {{sourceMissingId}} is missing':
      'Entity from {{sourceMissingName}} with id {{sourceMissingId}} is missing',
    'Failed Uniqueness Checks': 'Failed Uniqueness Checks',
    'In the file uploaded, the sheet {{ sheet }} has a large number of columns. All columns after column {{ max_num }} were ignored. Note that these may be blank and they should be deleted.':
      'In the file uploaded, the sheet {{ sheet }} has a large number of columns. All columns after column {{ max_num }} were ignored. Note that these may be blank and they should be deleted.',
    Accept: 'Accept',
    Reject: 'Reject',
    Menu: 'Menu',
    'Navis Rail': 'Navis Rail',
    'Uploading...': 'Uploading...',
    'Revision {{rev.id}} invalid.': 'Revision {{rev.id}} invalid.',
    "Revision {{rev.id}} missing field 'meta'.":
      "Revision {{rev.id}} missing field 'meta'.",
    'Schema "{{rev.meta.type}}" not found.':
      'Schema "{{rev.meta.type}}" not found.',
    '{{msg}} The document you are opening may be of the wrong type':
      '{{msg}} The document you are opening may be of the wrong type',
    'The sheet {{ sheet }} is empty. It requires the columns {{ columns }}':
      'The sheet {{ sheet }} is empty. It requires the columns {{ columns }}',
    'Service design name': 'Service design name',
    'Scenario name': 'Scenario name',
    'Copy plan and scenario': 'Copy plan and scenario',
    'Copy scenario': 'Copy scenario',
    'Copy plan': 'Copy plan',
    'Copy plan within scenario': 'Copy plan within scenario',
    'The underlying scenario has changed':
      'The underlying scenario has changed',
    'No rows': 'No rows',
    'Remote Rest': 'Remote Rest',
    'Location 1': 'Location 1',
    'Location 2': 'Location 2',
    Location: 'Location',
  },
  finder: {
    'To paste a copy of': 'To paste a copy of',
    'select a': 'select a',
    'plan and press this': 'plan and press this',
    'Out of Date': 'Out of Date',
    'There was an error copying your document.':
      'There was an error copying your document.',
    'There was an error loading documents from the server.':
      'There was an error loading documents from the server.',
    'There was an error loading the platform configuration from the server.':
      'There was an error loading the platform configuration from the server.',
    'There was an error archiving your document.':
      'There was an error archiving your document.',
    'Failed to create document.': 'Failed to create document.',
    Open: 'Open',
    Copy: 'Copy',
    Archive: 'Archive',
    Scenario: 'Scenario',
    scenario: 'scenario',
    Plan: 'Plan',
    plan: 'plan',
    'service-design': 'service-design',
    'New Document': 'New Document',
    'Import document': 'Import document',
    'Export fleet plans': 'Export fleet plans',
    'Exporting {{documentLength}} {{documentType}} documents':
      'Exporting {{documentLength}} {{documentType}} documents',
    'Plans selected': 'Plans selected',
    'Logs are updated hourly': 'Logs are updated hourly',
    'Load more': 'Load more',
    'All logs loaded': 'All logs loaded',
    'Updated {{timestamp}}': 'Updated {{timestamp}}',
    'by {{username}}': 'by {{username}}',
    Warning: 'Warning',
    'MCS Snapshot Type': 'MCS Snapshot Type',
    'Freight Yard Block Snapshot': 'Freight Yard Block Snapshot',
    Accept: 'Accept',
    Reject: 'Reject',
    'Download Unrounded Train Starts': 'Download Unrounded Train Starts',
    'Yard To Train Block Snapshot': 'Yard To Train Block Snapshot',
    'Type more to search': 'Type more to search',
    '(archived)': '(archived)',
    '(parent document archived)': '(parent document archived)',
  },
  scenario: {
    Update: 'Update',
    Edit: 'Edit',
    Add: 'Add',
    Delete: 'Delete',
    'Access Group': 'Access Group',
    'This belongs to {{number}} {{collection}}':
      'This belongs to {{number}} {{collection}}', // As in this belong 5 trains
    Okay: 'Okay',
    'Are you sure?': 'Are you sure?',
    '<p>Warning: you are about to delete an item that other parts of the tool may rely on.</p><p>Deleting this entity will also delete any other entities that refer to it in this scenario and in any attached plans.</p><p>Make sure you understand the consequences before proceeding.</p>':
      '<p>Warning: you are about to delete an item that other parts of the tool may rely on.</p><p>Deleting this entity will also delete any other entities that refer to it in this scenario and in any attached plans.</p><p>Make sure you understand the consequences before proceeding.</p>',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    Save: 'Save',
    ID: 'ID',
    'Primary Colour': 'Primary Colour',
    Name: 'Name',
    'Specify Volume Unit': 'Specify Volume Unit',
    'Specify Volume Unit of Measurement': 'Specify Volume Unit of Measurement',
    'Wagon Class': 'Wagon Class',
    'Unable to delete entity of {{entity}}':
      'Unable to delete entity of {{entity}}', // As in unable to delete entity of train
    'Axle Load Limit (t)': 'Axle Load Limit (t)',
    'Distance (km)': 'Distance (km)',
    'Max Locos / Train': 'Max Locos / Train',
    'Max Train Length (m)': 'Max Train Length (m)',
    'Max Train Passes': 'Max Train Passes',
    'Min Headway': 'Min Headway',
    'Loco Haul Cost ($)': 'Loco Haul Cost ($)',
    'Access Base Cost ($)': 'Access Base Cost ($)',
    'Access ($/t)': 'Access ($/t)',
    'Update Fuel Price': 'Update Fuel Price',
    'Crew Settings': 'Crew Settings',
    'Fuel Price': 'Fuel Price',
    'Fuel Price ($)': 'Fuel Price ($)',
    'Changeover Duration': 'Changeover Duration',
    'Changeover Duration (HHMM)': 'Changeover Duration (HHMM)',
    'Set Fuel Price': 'Set Fuel Price',
    'Set Crew Settings': 'Set Crew Settings',
    'Fuel Price Per Litre ($)': 'Fuel Price Per Litre ($)',
    'Crew Route Knowledge': 'Crew Route Knowledge',
    'Locomotive Load Tables': 'Locomotive Load Tables',
    Settings: 'Settings',
    'Train Type': 'Train Type',
    'Load Category': 'Load Category',
    'Forward Tonnage (t)': 'Forward Tonnage (t)',
    'Forward (HHMM)': 'Forward (HHMM)',
    'Extra Forward (HHMM)': 'Extra Forward (HHMM)',
    'Extra Reverse (HHMM)': 'Extra Reverse (HHMM)',
    'Reverse Tonnage (t)': 'Reverse Tonnage (t)',
    'Reverse (HHMM)': 'Reverse (HHMM)',
    Comment: 'Comment',
    'Return to Plan': 'Return to Plan',
    'Loading Rate': 'Loading Rate',
    'Unloading Rate': 'Unloading Rate',
    Code: 'Code',
    'Provision Time': 'Provision Time',
    'Pre-Departure Time': 'Pre-Departure Time',
    'Post-Arrival Time': 'Post-Arrival Time',
    'Demand Attach Time': 'Demand Attach Time',
    'Demand Detach Time': 'Demand Detach Time',
    'Attach/Detach Cost': 'Attach/Detach Cost',
    'Can Turn Locos': 'Can Turn Locos',
    'Allow Remote Rest': 'Allow Remote Rest',
    Timezone: 'Timezone',
    'Lead Capable': 'Lead Capable',
    'Axle Load (t)': 'Axle Load (t)',
    'Cost per week ($)': 'Cost per week ($)',
    'Cost per working hour ($)': 'Cost per working hour ($)',
    'Cost per working KM ($)': 'Cost per working KM ($)',
    'Cost per KM ($)': 'Cost per KM ($)',
    'Fuel burn per yard working hour (l)':
      'Fuel burn per yard working hour (l)',
    'Fuel burn per working KM (l)': 'Fuel burn per working KM (l)',
    'Fuel burn per KM (l)': 'Fuel burn per KM (l)',
    'Requires ECP Brakes': 'Requires ECP Brakes', // As in Requires Electronically controller pneumatic brakes
    'ECP Braking': 'ECP Braking',
    Abbreviation: 'Abbreviation',
    Offset: 'Offset',
    'UTC Offset': 'UTC Offset',
    '+30 mins': '+30 mins',
    '-30 mins': '-30 mins',
    'Max Tonnage (t)': 'Max Tonnage (t)',
    Yard: 'Yard',
    'Forward transit time': 'Forward transit time',
    'Reverse transit time': 'Reverse transit time',
    'Additional forward transit time': 'Additional forward transit time',
    'Additional reverse transit time': 'Additional reverse transit time',
    'Cargo Type': 'Cargo Type',
    Volume: 'Volume',
    'Volume Units per Wagon': 'Volume Units per Wagon',
    'Empty Weight (t)': 'Empty Weight (t)',
    'Length (m)': 'Length (m)',
    'Weight (t)': 'Weight (t)',
    'Can Lead': 'Can Lead',
    'Access Rights': 'Access Rights',
    'Access Rights for': 'Access Rights for',
    'Load Tables for': 'Load Tables for',
    'Volume Unit': 'Volume Unit',
    Tonnage: 'Tonnage',
    'Tonnage (t)': 'Tonnage (t)',
    'Average Tare Weight (t)': 'Average Tare Weight (t)',
    'Length (ft)': 'Length (ft)',
    'You must define at least 1 Wagon before adding a Cargo Type':
      'You must define at least 1 Wagon before adding a Cargo Type',
    'Location Compatibility of {{name}}': 'Location Compatibility of {{name}}',
    'Wagon Compatibility of {{name}}': 'Wagon Compatibility of {{name}}',
    'Transit times for': 'Transit times for',
    Business: 'Business',
    Network: 'Network',
    'Loco Classes': 'Loco Classes',
    'Loco Class': 'Loco Class',
    Cargos: 'Cargos',
    'Compatible Locations': 'Compatible Locations',
    'Compatible Wagons': 'Compatible Wagons',
    Groups: 'Groups',
    'Train Types': 'Train Types',
    'Access Groups': 'Access Groups',
    Locations: 'Locations',
    Corridors: 'Corridors',
    'Load Tables': 'Load Tables',
    'Transit Times': 'Transit Times',
    'Transit Time': 'Transit Time',
    Timezones: 'Timezones',
    Definitions: 'Definitions',
    'Load Categories': 'Load Categories',
    Types: 'Types',
    Wagons: 'Wagons',
    '...and here is your export.': '...and here is your export.',
    'Invalid Access Right': 'Invalid Access Right',
    'Access Right (id={{entity.id}}) refers to a missing lococlass':
      'Access Right (id={{entity.id}}) refers to a missing lococlass',
    'Access Right (id={{entity.id}}) refers to a missing access group':
      'Access Right (id={{entity.id}}) refers to a missing access group',
    'Invalid Cargo Type': 'Invalid Cargo Type',
    'CargoType (id={{entity.id}}) refers to a missing wagon':
      'CargoType (id={{entity.id}}) refers to a missing wagon',
    'Invalid Corridor': 'Invalid Corridor',
    'Corridor (id={{entity.id}}) refers to a missing access group':
      'Corridor (id={{entity.id}}) refers to a missing access group',
    'Corridor (id={{entity.id}}) refers to a missing location':
      'Corridor (id={{entity.id}}) refers to a missing location',
    'Invalid Load table entry': 'Invalid Load table entry',
    'Load table entry (id={{entity.id}}) refers to a missing corridor':
      'Load table entry (id={{entity.id}}) refers to a missing corridor',
    'Load table entry (id={{entity.id}}) refers to a missing train type':
      'Load table entry (id={{entity.id}}) refers to a missing train type',
    'Load table entry (id={{entity.id}}) refers to a missing load category':
      'Load table entry (id={{entity.id}}) refers to a missing load category',
    'Invalid Load Cargo Compatibility': 'Invalid Load Cargo Compatibility',
    'Location Cargo Compatibility (id={{entity.id}}) refers to a missing location':
      'Location Cargo Compatibility (id={{entity.id}}) refers to a missing location',
    'Location Cargo Compatibility (id={{entity.id}}) refers to a missing cargo type':
      'Location Cargo Compatibility (id={{entity.id}}) refers to a missing cargo type',
    'Invalid Location': 'Invalid Location',
    'Location (id={{entity.id}}) refers to a missing timezone':
      'Location (id={{entity.id}}) refers to a missing timezone',
    'Invalid Lococlass': 'Invalid Lococlass',
    'Lococlass (id={{entity.id}}) refers to a missing Load Category':
      'Lococlass (id={{entity.id}}) refers to a missing Load Category',
    'Invalid Transit Time': 'Invalid Transit Time',
    'Transit Time (id={{entity.id}}) refers to a missing Corridor':
      'Transit Time (id={{entity.id}}) refers to a missing Corridor',
    'Transit Time (id={{entity.id}}) refers to a missing Train Type':
      'Transit Time (id={{entity.id}}) refers to a missing Train Type',
    'Invalid Wagon Cargo Compatibility': 'Invalid Wagon Cargo Compatibility',
    'Wagon Cargo Compatibility (id={{entity.id}}) refers to a missing wagon':
      'Wagon Cargo Compatibility (id={{entity.id}}) refers to a missing wagon',
    'Wagon Cargo Compatibility (id={{entity.id}}) refers to a missing cargo type':
      'Wagon Cargo Compatibility (id={{entity.id}}) refers to a missing cargo type',
    'Load category and train type already exists for corridor {{name}}':
      'Load category and train type already exists for corridor {{name}}',
    'Locomotives with Access Rights': 'Locomotives with Access Rights',
    'Wagons with Access Rights': 'Wagons with Access Rights',
    Horsepower: 'Horsepower',
    'Horsepower (hp)': 'Horsepower (hp)',
    'Out of Network': 'Out of Network',
    'Foreign Railroad': 'Foreign Railroad',
    'There is already a corridor between {{loc1}} and {{loc2}} with this via description':
      'There is already a corridor between {{loc1}} and {{loc2}} with this via description',
    'Max Occupying Trains': 'Max Occupying Trains',
    'Dwell Allowance': 'Dwell Allowance',
    Headway: 'Headway',
    'Headway (HHMM)': 'Headway (HHMM)',
    Clearance: 'Clearance',
    'Clearance (HHMM)': 'Clearance (HHMM)',
    'Invalid LV Trip': 'Invalid LV Trip',
    'LV Trips': 'LV Trips',
    'LV Trip': 'LV Trip',
    'LV Trip (id={{entity.id}}) refers to a missing Location':
      'LV Trip (id={{entity.id}}) refers to a missing Location',
    'Base Cost ($)': 'Base Cost ($)',
    'Duration (HHMM)': 'Duration (HHMM)',
    Duration: 'Duration',
    'There already exists an LV Trip between these locations':
      'There already exists an LV Trip between these locations',
    'Locations should be distinct': 'Locations should be distinct',
    'Crew Types': 'Crew Types',
    'Crew Type': 'Crew Type',
    'Crew Type (id={{entity.id}}) refers to a missing Location':
      'Crew Type (id={{entity.id}}) refers to a missing Location',
    'Hourly Rate ($)': 'Hourly Rate ($)',
    'Sign On Duration': 'Sign On Duration',
    'Sign Off Duration': 'Sign Off Duration',
    'Minimum Shift Duration': 'Minimum Shift Duration',
    'Maximum Shift Duration': 'Maximum Shift Duration',
    'Minimum Home Rest': 'Minimum Home Rest',
    'Meal Break Duration': 'Meal Break Duration',
    Crew: 'Crew',
    'Location 2 should be different from Location 1':
      'Location 2 should be different from Location 1',
    'Is a driver?': 'Is a driver?',
    'On-costs (%)': 'On-costs (%)',
    'Allowance ($)': 'Allowance ($)',
    'Base Salary ($)': 'Base Salary ($)',
    'APM Base Salary ($)': 'APM Base Salary ($)',
    'Has knowledge of corridors': 'Has knowledge of corridors',
    'Duty Per Week': 'Duty Per Week',
    'Duty Cycle Duration (weeks)': 'Duty Cycle Duration (weeks)',
    'Annual Leave': 'Annual Leave',
    'Annual Leave Loading (%)': 'Annual Leave Loading (%)',
    'RDOs Required Per Line': 'RDOs Required Per Line',
    'Maximum Days Between RDOs': 'Maximum Days Between RDOs',
    'Earliest Sign On After Relief': 'Earliest Sign On After Relief',
    'Max FAID Score': 'Max FAID Score',
    'Maximum Weeks Between Weekend RDOs': 'Maximum Weeks Between Weekend RDOs',
    'Foreign Railroads': 'Foreign Railroads',
    'LV Settings': 'LV Settings',
    'Coupled Set Size': 'Coupled Set Size',
    'Set LV Settings': 'Set LV Settings',
    Costing: 'Costing',
    Rostering: 'Rostering',
    Shifts: 'Shifts',
    'Meal Break Window Duration': 'Meal Break Window Duration',
    'Required for shifts longer than': 'Required for shifts longer than',
    'Train Graph': 'Train Graph',
    Layout: 'Layout',
    'Can Change Locos': 'Can Change Locos',
    'Remote Rest Cost ($)': 'Remote Rest Cost ($)',
    'Attach/Detach Cost ($)': 'Attach/Detach Cost ($)',
    'Wagon Compatibility of {{ code }}': 'Wagon Compatibility of {{ code }}',
    Wagon: 'Wagon',
    'Wagon Compatibility': 'Wagon Compatibility',
    'Location Cargo Compatibility': 'Location Cargo Compatibility',
    'Load Table': 'Load Table',
    'Uses Tonnage?': 'Uses Tonnage?',
    'Business Group': 'Business Group',
    'Are you sure you want to delete this?':
      'Are you sure you want to delete this?',
    'No loco classes': 'No loco classes',
    'Cargo Compatibilities': 'Cargo Compatibilities',
    'Minimum Duration for Costing': 'Minimum Duration for Costing',
    'Meal Allowance ($)': 'Meal Allowance ($)',
    'Meal Allowance Period': 'Meal Allowance Period',
    'Minimum Remote Rest Duration': 'Minimum Remote Rest Duration',
    'Can Remotely Rest': 'Can Remotely Rest',
    'Meal Break Required for shifts longer than':
      'Meal Break Required for shifts longer than',
    Corridor: 'Corridor',
    'Copy Corridor': 'Copy Corridor',
    'Corridor "{{corridor}}" will be duplicated with all linked properties except for trains from associated plans':
      'Corridor "{{corridor}}" will be duplicated with all linked properties except for trains from associated plans',
  },
  'service-design': {
    'Document repair function failed': 'Document repair function failed',
    'A {{collectionName}} repair function failed.':
      'A {{collectionName}} repair function failed.',
    'Go to scenario': 'Go to scenario',
    'Link time axis to upper graph': 'Link time axis to upper graph',
    'Display shift names': 'Display shift names',
    'Show {{toggle}}': 'Show {{toggle}}',
    '<0></0> has duplicate starts on {{duplicates}}.':
      '<0></0> has duplicate starts on {{duplicates}}.',
    'Train {{entity.start.name}} activities at location {{entity.origin.code}} do not fit in dwell.':
      'Train {{entity.start.name}} activities at location {{entity.origin.code}} do not fit in dwell.',
    'Shift {{shift2.name}} is too soon after {{shift1.name}} on {{pool.name}} roster line {{line.num}}':
      'Shift {{shift2.name}} is too soon after {{shift1.name}} on {{pool.name}} roster line {{line.num}}',
    'Duplicate Starts': 'Duplicate Starts',
    'Balance LVs': 'Balance LVs',
    'Log Message': 'Log Message',
    'Missing Lococlass': 'Missing Lococlass',
    'Consist refers to a missing lococlass.':
      'Consist refers to a missing lococlass.',
    'Orphaned Consist': 'Orphaned Consist',
    '{{selectedDemands}} Selected Demand_plural':
      '{{selectedDemands}} Selected Demands',
    '{{selectedDemands}} Selected Demand':
      '{{selectedDemands}} Selected Demand',
    'Consist with id {{entity.id}} refers to a missing leg.':
      'Consist with id {{entity.id}} refers to a missing leg.',
    'Missing Train': 'Missing Train',
    'Missing Corridor': 'Missing Corridor',
    '{{entity.train.name}} is missing a corridor on one of its legs':
      '{{entity.train.name}} is missing a corridor on one of its legs',
    'Axle load exceeded': 'Axle load exceeded',
    'The axle load of the consist is {{entity.consist.axleLoad}} but should be at most {{entity.corridor.axleLoadLimit}}.':
      'The axle load of the consist is {{entity.consist.axleLoad}} but should be at most {{entity.corridor.axleLoadLimit}}.',
    'Too many locos': 'Too many locos',
    'The maximum number of locos on this corridor is {{entity.corridor.maxLocosPerTrain}}.':
      'The maximum number of locos on this corridor is {{entity.corridor.maxLocosPerTrain}}.',
    'Minimum number of lead locos not satisfied':
      'Minimum number of lead locos not satisfied',
    'The consist requires {{entity.start.minLeadLocos}} lead locos but only has {{entity.consist.numLeadLocos}}':
      'The consist requires {{entity.start.minLeadLocos}} lead locos but only has {{entity.consist.numLeadLocos}}',
    'Missing Load Tables': 'Missing Load Tables',
    'The following locos do not have load tables for this leg: {{missingLoadTables}}.':
      'The following locos do not have load tables for this leg: {{missingLoadTables}}.',
    'Exceeded tonnage': 'Exceeded tonnage',
    'Leg pulls {{tonnage}}t but train type has a maximum of {{maxTonnage}}t.':
      'Leg pulls {{tonnage}}t but train type has a maximum of {{maxTonnage}}t.',
    'Train underpowered': 'Train underpowered',
    'Incompatible wagons': 'Incompatible wagons',
    '[{{invalidAllocationsMessage}}] is incompatible with {{entity.cargoType.name}}':
      '[{{invalidAllocationsMessage}}] is incompatible with {{entity.cargoType.name}}',
    '{{entity.name}} has no compatible wagons defined.':
      '{{entity.name}} has no compatible wagons defined.',
    'No compatible wagons available for demand':
      'No compatible wagons available for demand',
    'Not enough wagons assigned to demand':
      'Not enough wagons assigned to demand',
    '{{entity.name}} requires {{entity.volume}}{{entity.volumeUnit}}. Wagons have {{entity.wagonset.capacity}}{{entity.volumeUnit}} capacity':
      '{{entity.name}} requires {{entity.volume}}{{entity.volumeUnit}}. Wagons have {{entity.wagonset.capacity}}{{entity.volumeUnit}} capacity',
    'Invalid Loco Access': 'Invalid Loco Access',
    '[{{invalidLocos}}] locos on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.':
      '[{{invalidLocos}}] locos on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.',
    '[{{entity.invalidWagons}}] wagons assigned to freight demand {{entity.serviceName}} are not permitted from {{entity.origin}} to {{entity.destination}}.':
      '[{{entity.invalidWagons}}] wagons assigned to freight demand {{entity.serviceName}} are not permitted from {{entity.origin}} to {{entity.destination}}.',
    '[{{invalidWagons}}] wagons on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.':
      '[{{invalidWagons}}] wagons on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.',
    'Terminating consist must be available':
      'Terminating consist must be available',
    'Inconsistency between available flag and following consist':
      'Inconsistency between available flag and following consist',
    'Inconsistency between available flag and following working locos':
      'Inconsistency between available flag and following working locos',
    'Locos must become available when the train terminates.':
      'Locos must become available when the train terminates.',
    'Since the following leg uses a different consist the locos must become available at end of leg.':
      'Since the following leg uses a different consist the locos must become available at end of leg.',
    'Active consist change implies locos must become available at the end of the leg.':
      'Active consist change implies locos must become available at the end of the leg.',
    'Insufficient time for activities': 'Insufficient time for activities',
    '<0></0> departs <3></3> {{duration}} too early for <8></8>.':
      '<0></0> departs <3></3> {{duration}} too early for <8></8>.',
    'Freight demand arrives late': 'Freight demand arrives late',
    '<0></0> arrives at <3></3> {{duration}} too late for <7></7>.':
      '<0></0> arrives at <3></3> {{duration}} too late for <7></7>.',
    'Leg departs too early. Train {{entity.start.name}} requires {{entity.minDwellDuration, durationString}} at location {{entity.origin.code}}.':
      'Leg departs too early. Train {{entity.start.name}} requires {{entity.minDwellDuration, durationString}} at location {{entity.origin.code}}.',
    'Train too long for corridor': 'Train too long for corridor',
    'Too many hauled locos': 'Too many hauled locos',
    'This train allows hauling of up to {{entity.start.maxHauledLocos}} locos but the consist hauls {{entity.consist.numHauledLocos}}.':
      'This train allows hauling of up to {{entity.start.maxHauledLocos}} locos but the consist hauls {{entity.consist.numHauledLocos}}.',
    'ECP Braking violated': 'ECP Braking violated',
    'Locos with ECP braking capability required.':
      'Locos with ECP braking capability required.',
    'Orphaned Loco Allocation': 'Orphaned Loco Allocation',
    'Loco Allocation ({{entity.id}}) refers to a missing consist':
      'Loco Allocation ({{entity.id}}) refers to a missing consist',
    'Insufficient resources': 'Insufficient resources',
    'The assignments suggest an additional {{spareMagnitude}} {{resourceSummary.name}}s are required':
      'The assignments suggest an additional {{spareMagnitude}} {{resourceSummary.name}}s are required',
    'Resource Imbalance': 'Resource Imbalance',
    '{{resourceSummary.name}} has a {{imbalance}} imbalance at {{location.code}}':
      '{{resourceSummary.name}} has a {{imbalance}} imbalance at {{location.code}}',
    'Demand ({{entity.id}}) has invalid assignments.':
      'Demand ({{entity.id}}) has invalid assignments.',
    'Missing Origin': 'Missing Origin',
    '{{entity.name}} does not have an origin':
      '{{entity.name}} does not have an origin',
    'Missing Destination': 'Missing Destination',
    '{{entity.name}} does not have a destination':
      '{{entity.name}} does not have a destination',
    'Missing Cargo Type': 'Missing Cargo Type',
    '{{entity.name}} does not have a cargo type':
      '{{entity.name}} does not have a cargo type',
    'Incomplete Demand Route': 'Incomplete Demand Route',
    '{{entity.name}} is incomplete': '{{entity.name}} is incomplete',
    'Incompatible cargo at origin': 'Incompatible cargo at origin',
    "{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be loaded at {{entity.origin.code}}":
      "{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be loaded at {{entity.origin.code}}",
    'Incompatible cargo at destination': 'Incompatible cargo at destination',
    "{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be unloaded at {{entity.destination.code}}":
      "{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be unloaded at {{entity.destination.code}}",
    'Invalid Train': 'Invalid Train',
    'Corridor Ends Changed': 'Corridor Ends Changed',
    'Train ({{entity.id}}) has invalid legs.':
      'Train ({{entity.id}}) has invalid legs.',
    'Any entities that reference this corridor may be materially altered. In some circumstances they will have to be deleted.':
      'Any entities that reference this corridor may be materially altered. In some circumstances they will have to be deleted.',
    'Missing Business Group': 'Missing Business Group',
    '{{entity.name}} refers to a missing business group':
      '{{entity.name}} refers to a missing business group',
    'Missing Type': 'Missing Type',
    '{{entity.name}} refers to a missing train type':
      '{{entity.name}} refers to a missing train type',
    '{{count}} Templated Train': '{{count}} Templated Train',
    '{{count}} Templated Train_plural': '{{count}} Templated Trains',
    '{{train}} is too close to train {{others}}':
      '{{train}} is too close to train {{others}}',
    '{{train}} is too close to train [{{others}}]_plural':
      '{{train}} is too close to trains [{{others}}]',
    'Select trains of a single type to batch edit':
      'Select trains of a single type to batch edit',
    'Opposing Trains on Corridor': 'Opposing Trains on Corridor',
    'Train Capacity Exceeded on Location':
      'Train Capacity Exceeded on Location',
    'Train {{trainA}} opposes {{trainB}} on {{corridor}}':
      'Train {{trainA}} opposes {{trainB}} on {{corridor}}',
    'Location: {{location.code}} has too many trains: [{{others}}]':
      'Location: {{location.code}} has too many trains: [{{others}}]',
    'Insufficient Headway Between Trains':
      'Insufficient Headway Between Trains',
    'Loco changes can only occur at a yard location':
      'Loco changes can only occur at a yard location',
    'Location {{entity.name}} has been selected as a loco change location despite not being marked as a yard.':
      'Location {{entity.name}} has been selected as a loco change location despite not being marked as a yard.',
    'Clear All Shifts': 'Clear All Shifts',
    'Clear All RDOs': 'Clear All RDOs',
    '[{{wagons}}] wagons assigned to freight demand <4></4> are not permitted from <7></7> to <10></10>.':
      '[{{wagons}}] wagons assigned to freight demand <4></4> are not permitted from <7></7> to <10></10>.',
    'Confirm {{actionName}}': 'Confirm {{actionName}} ',
    'Location does not allow remote rests':
      'Location does not allow remote rests',
    'remote rests not supported': 'remote rests not supported',
    'Remove Demand Wagons': 'Remove Demand Wagons',
    'Remove Demand Route': 'Remove Demand Route',
    'Edit Route Locks': 'Edit Route Locks',
    'Remove Routes': 'Remove Routes',
    'Remove Wagons': 'Remove Wagons',
    'Edit Wagons': 'Edit Wagons',
    assignmentWithCount: '{{count}} assignment',
    assignmentWithCount_plural: '{{count}} assignments',
    'Ok, continue': 'Ok, continue',
    'In order to adjusting the timing of this shift we need to un-roster it.':
      'In order to adjusting the timing of this shift we need to un-roster it.',
    'In order to adjusting the timing of this shift we need to un-roster it and the shift connected via the remote rest.':
      'In order to adjusting the timing of this shift we need to un-roster it and the shift connected via the remote rest.',
    Schedule: 'Schedule',
    'Template Schedule': 'Template Schedule',
    'Start Schedule': 'Start Schedule',
    '{{legCount}} legs': '{{legCount}} legs',
    'Locomotive Assignments': 'Locomotive Assignments',
    Roster: 'Roster',
    'Fleet Plan': 'Fleet Plan',
    'Haulage Plan': 'Haulage Plan',
    'Idle Report': 'Idle Report',
    'Engine run failed': 'Engine run failed',
    'Running auto-generation': 'Running auto-generation',
    'Result ready': 'Result ready',
    'Ready to start auto-generation': 'Ready to start auto-generation',
    'Terminating location must be a yard':
      'Terminating location must be a yard',
    '{{remaining}} of {{fleetTotal}} used':
      '{{remaining}} of {{fleetTotal}} used',
    'Fleet Total': 'Fleet Total',
    'Rostered Total': 'Rostered Total',
    'Unavailable Total': 'Unavailable Total',
    Surplus: 'Surplus',
    Cargo: 'Cargo',
    Customer: 'Customer',
    Volume: 'Volume',
    Tonnage: 'Tonnage',
    Origin: 'Origin',
    Destination: 'Destination',
    // These need to be in here even though they are static because they are
    // used in the info item component
    '{{entity.cargoType.name}}': '{{entity.cargoType.name}}',
    '{{entity.customer}}': '{{entity.customer}}',
    '{{entity.volume}}{{entity.volumeUnit}}':
      '{{entity.volume}}{{entity.volumeUnit}}',
    '{{entity.tonnage}}t': '{{entity.tonnage}}t',
    '{{entity.origin.code}}, {{entity.deliveryCutOffLocal, offsetString}}':
      '{{entity.origin.code}}, {{entity.deliveryCutOffLocal, offsetString}}',
    '{{entity.destination.code}}, {{entity.dueLocal, offsetString}}':
      '{{entity.destination.code}}, {{entity.dueLocal, offsetString}}',
    Route: 'Route',
    'Business Group': 'Business Group',
    Type: 'Type',
    'Min lead locos': 'Min lead locos',
    'Max hauled locos': 'Max hauled locos',
    'ECP Braking': 'ECP Braking',
    'Average Weekly KMs': 'Average Weekly KMs',
    Yes: 'Yes',
    No: 'No',
    'This train refers to one or more broken references. Please remove all items from this table.':
      'This train refers to one or more broken references. Please remove all items from this table.',
    '{{time, offsetString}}': '{{time, offsetString}}',
    Empties: 'Empties',
    Miscellaneous: 'Miscellaneous',
    '0m': '0m',
    'dep.': 'dep.',
    'arr.': 'arr.',
    Utilisation: 'Utilisation',
    Kilometres: 'Kilometres',
    Reports: 'Reports',
    Unavailabilities: 'Unavailabilities',
    Attach: 'Attach',
    'Pre Departure': 'Pre Departure',
    Transport: 'Transport',
    'Post Arrival': 'Post Arrival',
    Detach: 'Detach',
    'Unable to display the route table until broken links have been resolved.':
      'Unable to display the route table until broken links have been resolved.',
    '{{name}} has errors': '{{name}} has errors',
    'Displaying {{number}} of {{total}}': 'Displaying {{number}} of {{total}}',
    'Filter...': 'Filter...',
    Legs: 'Legs',
    Locomotives: 'Locomotives',
    'Scheduled Train': 'Scheduled Train',
    Quantity: 'Quantity',
    Reason: 'Reason',
    Overutilisation: 'Overutilisation',
    Idle: 'Idle',
    'Pulling Power': 'Pulling Power',
    'Haulage Capacity': 'Haulage Capacity',
    Unassigned: 'Unassigned',
    Hauled: 'Hauled',
    Unavailable: 'Unavailable',
    KMs: 'KMs',
    Wagons: 'Cars',
    wagons: 'cars',
    'Capacity {{capacity}}{{unit}}': 'Capacity {{capacity}}{{unit}}',
    Yards: 'Yards',
    'post-arrival': 'post-arrival',
    attach: 'attach',
    detach: 'detach',
    'loco-provisioning': 'loco-provisioning',
    'pre-departure': 'pre-departure',
    'custom-task': 'custom-task',
    dwell: 'dwell',
    loading: 'loading',
    transport: 'transport',
    unloading: 'unloading',
    'Crew for Loading': 'Crew for Loading',
    'Crew for Unloading': 'Crew for Unloading',
    Loading: 'Loading',
    'Task handover at {{time}}': 'Task handover at {{time}}',
    Unloading: 'Unloading',
    Update: 'Update',
    Add: 'Add',
    Assignment: 'Assignment',
    Train: 'Train',
    'Other Train': 'Other Train',
    'Continuing to': 'Continuing to',
    'Confirm Delete': 'Confirm Delete',
    'Are you sure you want to {{actionName}} {{name}}?':
      'Are you sure you want to {{actionName}} {{name}}?',
    'Lock the route of': 'Lock the route of',
    'Unlock the route of': 'Unlock the route of',
    Delete: 'Delete',
    Cancel: 'Cancel',
    'The following demands will be unrouted if you wish to proceed:':
      'The following demands will be unrouted if you wish to proceed:',
    '{{number}} demands will be unrouted if you wish to proceed.':
      '{{number}} demands will be unrouted if you wish to proceed.',
    'Max Demands Per Train': 'Max Demands Per Train',
    'Max Wait Time': 'Max Wait Time',
    'Optimality Gap (%)': 'Optimality Gap (%)',
    'Rollout Timestep': 'Rollout Timestep',
    'Max Engine Runtime': 'Max Engine Runtime',
    'Cost Per Train Hour': 'Cost Per Train Hour',
    'Fixed Cost Per Train': 'Fixed Cost Per Train',
    'Early Freight Reward Per Hour': 'Early Freight Reward Per Hour',
    'Balance Wagons': 'Balance Wagons',
    'Cost of leaving leg uncovered': 'Cost of leaving leg uncovered',
    'Penalty for breaking lock': 'Penalty for breaking lock',
    'Max Consist Configurations': 'Max Consist Configurations',
    'Solve Time Limit': 'Solve Time Limit',
    'Short Consist Change Duration': 'Short Consist Change Duration',
    'Short Consist Change Penalty': 'Short Consist Change Penalty',
    'Penalty for changing a loco from current':
      'Penalty for changing a loco from current',
    'Default Train Type': 'Default Train Type',
    'Penalty Per Wagon Over Capacity': 'Penalty Per Wagon Over Capacity',
    'Uncovered Demand Penalty': 'Uncovered Demand Penalty',
    'Wagon Repositioning Cost': 'Wagon Repositioning Cost',
    'Run Engine': 'Run Engine',
    Start: 'Start',
    Abort: 'Abort',
    'Locomotive Plan': 'Locomotive Plan',
    'Service Plan': 'Service Plan',
    'Train Pathing': 'Train Pathing',
    'Shift Plan': 'Shift Plan',
    'Engine Failed': 'Engine Failed',
    'Result Ready': 'Result Ready',
    'Running...': 'Running...',
    'Queueing...': 'Queueing...',
    Discard: 'Discard',
    Accept: 'Accept',
    Reject: 'Reject',
    Review: 'Review',
    'Auto-generation In Progress': 'Auto-generation In Progress',
    Outgoing: 'Outgoing',
    Incoming: 'Incoming',
    'Review engine roster': 'Review engine roster',
    'The following trains have new consists:':
      'The following trains have new consists:',
    'Only show broken locks': 'Only show broken locks',
    'Only show availability changes': 'Only show availability changes',
    'Stop Engine?': 'Stop Engine?',
    'Select an item...': 'Select an item...',
    'Working Locos': 'Working Locos',
    'Hauled Locos': 'Hauled Locos',
    'Through Wagons': 'Through Wagons',
    'Wagon Attachments Before Departure': 'Wagon Attachments Before Departure',
    'Wagon Detachments After Arrival': 'Wagon Detachments After Arrival',
    'Reroute {{number}} trains': 'Reroute {{number}} trains',
    Reroute: 'Reroute',
    Corridor: 'Corridor',
    'No common corridor': 'No common corridor',
    'Replacement route': 'Replacement route',
    'No replacement route': 'No replacement route',
    Departs: 'Departs',
    Arrives: 'Arrives',
    'Additional Miscellaneous Tonnage': 'Additional Miscellaneous Tonnage',
    'Apply Transit Time': 'Apply Transit Time',
    Save: 'Save',
    'Transit Time:': 'Transit Time:',
    'N/A': 'N/A',
    Leg: 'Leg',
    Prepend: 'Prepend',
    Edit: 'Edit',
    'Lock Working Locos': 'Lock Working Locos',
    Available: 'Available',
    'Availability Lock': 'Availability Lock',
    'Remove Working Locos': 'Remove Working Locos',
    'Remove Hauled Locos': 'Remove Hauled Locos',
    Allocation: 'Allocation',
    'Loco Fleet': 'Loco Fleet',
    'Wagon Fleet': 'Wagon Fleet',
    'Total Available': 'Total Available',
    'Fleet Unavailability': 'Fleet Unavailability',
    Demand: 'Demand',
    Name: 'Name',
    'Cargo Type': 'Cargo Type',
    'Freight Delivery Cut off': 'Freight Delivery Cut off',
    'Freight Availability at Destination':
      'Freight Availability at Destination',
    Comment: 'Comment',
    Time: 'Time',
    Duration: 'Duration',
    'Volume {{unit}}': 'Volume {{unit}}',
    'Tonnage per {{unit}}': 'Tonnage per {{unit}}',
    Filter: 'Filter',
    'Filter Custom Tasks': 'Filter Custom Tasks',
    Clear: 'Clear',
    ID: 'ID',
    Lock: 'Lock',
    Unlock: 'Unlock',
    'Edit {{number}} {{entities}}': 'Edit {{number}} {{entities}}',
    Trains: 'Trains',
    'Train Type': 'Train Type',
    'Filter Trains': 'Filter Trains',
    'Any Lock': 'Any Lock',
    'Any Warning': 'Any Warning',
    'Lock Types': 'Lock Types',
    Warnings: 'Warnings',
    'Tonnage Threshold': 'Tonnage Threshold',
    tonnes: 'tonnes',
    'Lightest tonnage exceeds': 'Lightest tonnage exceeds',
    'Lightest tonnage does not exceed': 'Lightest tonnage does not exceed',
    'Heaviest tonnage exceeds': 'Heaviest tonnage exceeds',
    'Heaviest tonnage does not exceed': 'Heaviest tonnage does not exceed',
    'Train Lock': 'Train Lock',
    'Working Loco Lock': 'Working Loco Lock',
    'Lock Wagons': 'Lock Wagons',
    HHMM: 'HHMM',
    'Early Freight Reward': 'Early Freight Reward',
    'CONNECTION FAILED: retrying for {{secondsLeft}} more second':
      'CONNECTION FAILED: retrying for {{secondsLeft}} more second',
    'CONNECTION FAILED: retrying for {{secondsLeft}} more second_plural':
      'CONNECTION FAILED: retrying for {{secondsLeft}} more seconds',
    'Connection to the engine was lost for over {{ entity.SECONDS_UNTIL_POLL_FAILURE }} seconds, please contact your Biarri representative with the job id {{ entity.jobId }}':
      'Connection to the engine was lost for over {{ entity.SECONDS_UNTIL_POLL_FAILURE }} seconds, please contact your Biarri representative with the job id {{ entity.jobId }}',
    'There was an error accepting the engine run. Please contact your Biarri representative with the id {{entity.jobId}}':
      'There was an error accepting the engine run. Please contact your Biarri representative with the id {{entity.jobId}}',
    'There was an error starting the engine run. Please contact your Biarri representative with the following information:':
      'There was an error starting the engine run. Please contact your Biarri representative with the following information:',
    'Terminating Wagon Detachment': 'Terminating Wagon Detachment',
    'Terminating leg of train {{entity.startLeg.start.name}} has wagon detachs. This is not permitted.':
      'Terminating leg of train {{entity.startLeg.start.name}} has wagon detachs. This is not permitted.',
    'Terminating Loco Detachment': 'Terminating Loco Detachment',
    'Terminating leg {{entity.name}} has loco detachs. This is not permitted.':
      'Terminating leg {{entity.name}} has loco detachs. This is not permitted.',
    'Invalid Wagon Detachment': 'Invalid Wagon Detachment',
    'Leg {{entity.name}} has invalid detachments.':
      'Leg {{entity.name}} has invalid detachments.',
    'Invalid Loco Detachment': 'Invalid Loco Detachment',
    'Orphaned Loco Event': 'Orphaned Loco Event',
    'Loco Event ({{entity.id}}) refers to a missing leg':
      'Loco Event ({{entity.id}}) refers to a missing leg',
    'Working Loco Attachments Before Departure':
      'Working Loco Attachments Before Departure',
    'Working Loco Detachments After Arrival':
      'Working Loco Detachments After Arrival',
    'Hauled Loco Attachments Before Departure':
      'Hauled Loco Attachments Before Departure',
    'Hauled Loco Detachments After Arrival':
      'Hauled Loco Detachments After Arrival',
    'Unable to run engine until broken links have been resolved':
      'Unable to run engine until broken links have been resolved',
    'Cannot return a demand to a location it has previously visited':
      'Cannot return a demand to a location it has previously visited',
    'Cannot be the origin': 'Cannot be the origin',
    'Crew Pools': 'Crew Pools',
    'Crew Pool': 'Crew Pool',
    'Crew Required?': 'Crew Required?',
    'This crew type has already been added to the plan':
      'This crew type has already been added to the plan',
    'Filter Crew Pools': 'Filter Crew Pools',
    'Invalid Shift': 'Invalid Shift',
    'Shift Too Long': 'Shift Too Long',
    'Driving assignments overlap with other assignments in shift':
      'Driving assignments overlap with other assignments in shift',
    'Driving assignments [{{assignment1}}, {{assignment2}}] overlap in shift <5></5> ({{pool}}).':
      'Driving assignments [{{assignment1}}, {{assignment2}}] overlap in shift <5></5> ({{pool}}).',
    'Cannot assign non driving tasks from different locations':
      'Cannot assign non driving tasks from different locations',
    'Overlapping assigned tasks [{{entity.assignment1}}, {{entity.assignment2}}] on shift {{entity.signOnLocal, offsetString}}':
      'Overlapping assigned tasks [{{entity.assignment1}}, {{entity.assignment2}}] on shift {{entity.signOnLocal, offsetString}}',
    'Shift assignments [{{ass1Name}}, {{ass2Name}}] occur at different locations [<5></5>, <8></8>] in shift <11></11> ({{pool}}).':
      'Shift assignments [{{ass1Name}}, {{ass2Name}}] occur at different locations [<5></5>, <8></8>] in shift <11></11> ({{pool}}).',
    'Shift <1></1> ({{pool}}) duration is {{duration}} which exceeds the maximum shift duration of {{maxDuration}}.':
      'Shift <1></1> ({{pool}}) duration is {{duration}} which exceeds the maximum shift duration of {{maxDuration}}.',
    'Shift (id={{entity.id}}) refers to a missing crew pool':
      'Shift (id={{entity.id}}) refers to a missing crew pool',
    'Invalid Driver Assignment': 'Invalid Driver Assignment',
    'Driver Assignment': 'Driver Assignment',
    'Driver task': 'Driver task',
    'Driver tasks': 'Driver tasks',
    'Yard tasks': 'Yard tasks',
    'Driver Assignment (id={{entity.id}}) refers to a missing train':
      'Driver Assignment (id={{entity.id}}) refers to a missing train',
    'Driver Assignment (id={{entity.id}}) refers to a missing shift':
      'Driver Assignment (id={{entity.id}}) refers to a missing shift',
    'Invalid Local Assignment': 'Invalid Local Assignment',
    'Select a train first': 'Select a train first',
    'Local Assignment': 'Local Assignment',
    'Local Assignment (id={{entity.id}}) refers to a missing leg':
      'Local Assignment (id={{entity.id}}) refers to a missing leg',
    'Local Assignment (id={{entity.id}}) refers to a missing shift':
      'Local Assignment (id={{entity.id}}) refers to a missing shift',
    'Sign On': 'Sign On',
    'Sign Off': 'Sign Off',
    departing: 'departing',
    arriving: 'arriving',
    Caution: 'Caution',
    'Location Changeover': 'Location Changeover',
    'Train Changeover': 'Train Changeover',
    'Add Changeover': 'Add Changeover',
    Changeover: 'Changeover',
    'Adding a changeover to a train will unassign all its driver tasks.':
      'Adding a changeover to a train will unassign all its driver tasks.',
    'Removing a changeover from a train will unassign all its driver tasks.':
      'Removing a changeover from a train will unassign all its driver tasks.',
    'LV Fleet size': 'LV Fleet size',
    'No matching LV trip defined': 'No matching LV trip defined',
    'For {{entity.name}}': 'For {{entity.name}}',
    'matching documents': 'matching documents',
    'Include archived': 'Include archived',
    'Filter by Train': 'Filter by Train',
    'Filter by Task Timing': 'Filter by Task Timing',
    'All Tasks': 'All Tasks',
    'Task is within shift': 'Task is within shift',
    'Task within X window of shift': 'Task within X window of shift',
    'Start Time': 'Start Time',
    'Start Loc': 'Start Loc',
    'Start location': 'Start location',
    'Start Location': 'Start Location',
    'End Time': 'End Time',
    'End Loc': 'End Loc',
    'End Location': 'End Location',
    '{{numTasks}} tasks selected': '{{numTasks}} tasks selected',
    'Time available to travel': 'Time available to travel',
    'Time required to travel': 'Time required to travel',
    'driver-task': 'driver task',
    'Insufficient time for location changeover':
      'Insufficient time for location changeover',
    'Insufficient time for train changeover':
      'Insufficient time for train changeover',
    '<0></0> has insufficient time to perform changeover on {{event}} at <5></5>.':
      '<0></0> has insufficient time to perform changeover on {{event}} at <5></5>.',
    '<0></0> and <3></3> have insufficient time to perform a changeover on {{event}} at <8></8>.':
      '<0></0> and <3></3> have insufficient time to perform a changeover on {{event}} at <8></8>.',
    'The following shifts are affected': 'The following shifts are affected',
    'Occurs at a non-yard location': 'Occurs at a non-yard location',
    'Train departs too early': 'Train departs too early',
    'Locos on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which cannot change locos':
      'Locos on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which cannot change locos',
    'Wagons on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which is not a yard':
      'Wagons on train {{entity.startLeg.start.name}} attach/detach at {{entity.location.code}} which is not a yard',
    'Demand {{entity.name}} originates/terminates at a location which is not a yard':
      'Demand {{entity.name}} originates/terminates at a location which is not a yard',
    'Demand {{entity.service.name}} attach/detach occurs at {{entity.location.code}} is not a yard':
      'Demand {{entity.service.name}} attach/detach occurs at {{entity.location.code}} is not a yard',
    'Train {{ entity.name }} originates/terminates at a location which is not a yard':
      'Train {{ entity.name }} originates/terminates at a location which is not a yard',
    'Assigned work outside shift': 'Assigned work outside shift',
    'Shift <1></1> ({{pool}}) starting at {{shiftStart}} has work assigned that is outside the shift.':
      'Shift <1></1> ({{pool}}) starting at {{shiftStart}} has work assigned that is outside the shift.',
    'Crew Pool too small': 'Crew Pool too small',
    '<0></0> requires {{requiredQuantity}} members to cover the duty hours.':
      '<0></0> requires {{requiredQuantity}} members to cover the duty hours.',
    'Unqualified Driver': 'Unqualified Driver',
    'Driver is not qualified to drive Train <2></2> from {{ startLocationCode }} to {{ endLocationCode }}':
      'Driver is not qualified to drive Train <2></2> from {{ startLocationCode }} to {{ endLocationCode }}',
    'Driver is not qualified to perform task <0></0>':
      'Driver is not qualified to perform task <0></0>',
    '(Unassigned)': '(Unassigned)',
    'Originating:': 'Originating:',
    dep: 'dep',
    at: 'at',
    'Terminating:': 'Terminating:',
    arr: 'arr',
    'Continuing:': 'Continuing:',
    to: 'to',
    'Preserve loco / wagon plans': 'Preserve loco / wagon plans',
    'Preserve shift plan': 'Preserve shift plan',
    'Edit Warning Preferences': 'Edit Warning Preferences',
    'You have {{count}} warning disabled':
      'You have {{count}} warning disabled',
    'You have {{count}} warning disabled_plural':
      'You have {{count}} warnings disabled',
    'Select all': 'Select all',
    'Select all visible': 'Select all visible',
    'Deselect all': 'Deselect all',
    'Deselect all visible': 'Deselect all visible',
    'Miscellaneous Tasks': 'Miscellaneous Tasks',
    Category: 'Category',
    'Requires Driver': 'Requires Driver',
    'Requires Driver?': 'Requires Driver?',
    'End location different from start?': 'End location different from start?',
    '{{entity.name}} is not assigned to a shift':
      '{{entity.name}} is not assigned to a shift',
    'Loading Work is not assigned to a shift':
      'Loading Work is not assigned to a shift',
    'Loading work task {{taskName}} for <4></4> is not assigned to a shift.':
      'Loading work task {{taskName}} for <4></4> is not assigned to a shift.',
    'Leg task is not assigned to a shift':
      'Leg task is not assigned to a shift',
    'Leg task {{taskName}} for <3></3> is not assigned to a shift.':
      'Leg task {{taskName}} for <3></3> is not assigned to a shift.',
    'Custom task is not assigned to a shift':
      'Custom task is not assigned to a shift',
    '<0></0> is not assigned to a shift.':
      '<0></0> is not assigned to a shift.',
    Demands: 'Demands',
    'Demand Instances': 'Demand Instances',
    Instances: 'Instances',
    Locos: 'Locos',
    'Horsepower hours': 'Horsepower hours',
    'You can detach at most {{quantity}} {{wagonName}} wagons after arrival':
      'You can detach at most {{quantity}} {{wagonName}} wagons after arrival',
    'Assigned to shift': 'Assigned to shift',
    'Unassign task': 'Unassign task',
    'Penalty per shift': 'Penalty per shift',
    'Penalty per LV kilometer': 'Penalty per LV kilometer',
    'Penalty per Light Vehicle kilometer':
      'Penalty per Light Vehicle kilometer',
    'Penalty per missing meal break': 'Penalty per missing meal break',
    'Penalty per changeover': 'Penalty per changeover',
    'Penalty per single changeover': 'Penalty per single changeover',
    'Penalty per short changeover minute':
      'Penalty per short changeover minute',
    'Penalty per uncovered task': 'Penalty per uncovered task',
    'Penalty per LV Imbalance': 'Penalty per LV Imbalance',
    'Penalty per Light Vehicle Imbalance':
      'Penalty per Light Vehicle Imbalance',
    'Minimum changeover duration minutes':
      'Minimum changeover duration minutes',
    RO: 'RO',
    Driver: 'Driver',
    'Driver and RO': 'Driver and RO',
    'Unqualified Driver For Driving': 'Unqualified Driver For Driving',
    'Unqualified Driver For Loading': 'Unqualified Driver For Loading',
    'Unqualified Driver for Custom Task': 'Unqualified Driver for Custom Task',
    'Loading Tasks': 'Loading Tasks',
    'Rail Operator': 'Rail Operator',
    'Coupled Set Size': 'Coupled Set Size',
    '({{quantity}} more)': '({{quantity}} more)',
    '{{type}} ({{quantity}} more)': '{{type}} ({{quantity}} more)',
    Assignments: 'Assignments',
    'Infeasible wagon allocation': 'Infeasible wagon allocation',
    'Allocation of {{entity.quantity}} {{entity.wagon.name}} wagon(s) to {{entity.wagonset.service.name}} is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})':
      'Allocation of {{entity.quantity}} {{entity.wagon.name}} wagon(s) to {{entity.wagonset.service.name}} is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})',
    'Infeasible attach/detach of empty wagons':
      'Infeasible attach/detach of empty wagons',
    '{{displayName}} of {{entity.quantity}} {{entity.wagon.name}} wagons is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})':
      '{{displayName}} of {{entity.quantity}} {{entity.wagon.name}} wagons is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})',
    Attachment: 'Attachment',
    Detachment: 'Detachment',
    'No meal break opportunity in shift': 'No meal break opportunity in shift',
    'There is no valid meal break opportunity in shift <2></2> ({{pool}}).':
      'There is no valid meal break opportunity in shift <2></2> ({{pool}}).',
    'Invalid Roster Line': 'Invalid Roster Line',
    'Unrostered shift': 'Unrostered shift',
    'Shift <1></1> ({{pool}}) is unrostered.':
      'Shift <1></1> ({{pool}}) is unrostered.',
    'Roster line with id {{entity.id}} is not fully connected to the {{entity.crewPool.name}} roster':
      'Roster line with id {{entity.id}} is not fully connected to the {{entity.crewPool.name}} roster',
    'Tasks within shift': 'Tasks within shift',
    'Tasks close to shift': 'Tasks close to shift',
    'Tasks reachable by LV': 'Tasks reachable by LV',
    'Invalid Wagon Access': 'Invalid Wagon Access',
    'Demand Invalid Wagon Access': 'Demand Invalid Wagon Access',
    'Work is not assigned to a shift': 'Work is not assigned to a shift',
    'Insert New Line': 'Insert New Line',
    Line: 'Line',
    'Duty Cycle': 'Duty Cycle',
    'Delete Line': 'Delete Line',
    'Insert Line Below': 'Insert Line Below',
    'Mark {{day}} as RDO': 'Mark {{day}} as RDO',
    'Unmark {{day}} as RDO': 'Unmark {{day}} as RDO',
    'Mark As Relief Line': 'Mark As Relief Line',
    'Unmark As Relief Line': 'Unmark As Relief Line',
    'Edit RDO start...': 'Edit RDO start...',
    'Roster has no lines. Right click to add one':
      'Roster has no lines. Right click to add one',
    RDO: 'RDO',
    'Week (h)': 'Week (h)',
    'Go to shift {{name}} detail': 'Go to shift {{name}} detail',
    'Duty (h)': 'Duty (h)',
    FAID: 'FAID',
    '{{total}} total duty hours': '{{total}} total duty hours',
    '{{count}} lines': '{{count}} line',
    '{{count}} lines_plural': '{{count}} lines',
    '{{count}} relief': '{{count}} relief',
    '{{average}} duty hours per line': '{{average}} duty hours per line',
    '{{count}} RDOs': '{{count}} RDO',
    '{{count}} RDOs_plural': '{{count}} RDOs',
    '{{required}} required': '{{required}} required',
    'Shift Conflict': 'Shift Conflict',
    'RDO Conflict': 'RDO Conflict',
    'Relief Line Conflict': 'Relief Line Conflict',
    "Multi-day RDO doesn't begin at midnight":
      "Multi-day RDO doesn't begin at midnight",
    "{{entity.crewPool.name}} roster has a multi-day RDO that doesn't begin at midnight on line {{entity.num}}, {{day}}":
      "{{entity.crewPool.name}} roster has a multi-day RDO that doesn't begin at midnight on line {{entity.num}}, {{day}}",
    'Shift {{shift.name}} overlaps with an RDO on {{shift.pool.name}} roster line {{line.num}}':
      'Shift {{shift.name}} overlaps with an RDO on {{shift.pool.name}} roster line {{line.num}}',
    'Sign-on is too soon after relief on {{line.crewPool.name}} roster line {{line.num}}':
      'Sign-on is too soon after relief on {{line.crewPool.name}} roster line {{line.num}}',
    'Insufficient RDOs': 'Insufficient RDOs',
    'Insufficient Weekend RDOs': 'Insufficient Weekend RDOs',
    '<0></0> roster needs {{requiredRDOs}} RDOs but only has {{totalRDOs}}.':
      '<0></0> roster needs {{requiredRDOs}} RDOs but only has {{totalRDOs}}.',
    'Unassign shift': 'Unassign shift',
    'RDOs in Relief Line': 'RDOs in Relief Line',
    APM: 'APM',
    'RDO(s)': 'RDO(s)',
    '{{entity.crewPool.name}} roster has RDO(s) on relief line {{entity.num}}':
      '{{entity.crewPool.name}} roster has RDO(s) on relief line {{entity.num}}',
    '{{entity.crewPool.name}} roster has too much time between weekend RDOs at line {{entity.num}}':
      '{{entity.crewPool.name}} roster has too much time between weekend RDOs at line {{entity.num}}',
    'Missing location': 'Missing location',
    'Separated Driver Shifts': 'Separated Driver Shifts',
    'Customer Penalty Per Minute': 'Customer Penalty Per Minute',
    'Leg Timing Adjustment Penalty Per Minute':
      'Leg Timing Adjustment Penalty Per Minute',
    'Train Validity Penalty Per Minute': 'Train Validity Penalty Per Minute',
    'Train Conflict Avoidance Penalty': 'Train Conflict Avoidance Penalty',
    'Shift Validity Penalty Per Minute': 'Shift Validity Penalty Per Minute',
    '<0></0> requires an LV trip between <3></3> and <6></6> but none is configured.':
      '<0></0> requires an LV trip between <3></3> and <6></6> but none is configured.',
    'Insufficient time for LV trip': 'Insufficient time for LV trip',
    '<0></0> requires {{lvTripDuration}} but only {{availableDuration}} is available.':
      '<0></0> requires {{lvTripDuration}} but only {{availableDuration}} is available.',
    'Too many days without an RDO': 'Too many days without an RDO',
    '{{violation.line.crewPool.name}} roster has too many days without an RDO on line {{violation.line.num}}':
      '{{violation.line.crewPool.name}} roster has too many days without an RDO on line {{violation.line.num}}',
    'Shifts on line exceed fatigue limit':
      'Shifts on line exceed fatigue limit',
    'Shift {{shift.name}} on line {{shift.line.num}} in roster for {{shift.line.crewPool.name}} exceeds the maximum fatigue score':
      'Shift {{shift.name}} on line {{shift.line.num}} in roster for {{shift.line.crewPool.name}} exceeds the maximum fatigue score',
    'Missing Roster Head': 'Missing Roster Head',
    '{{entity.name}} requires a roster head.':
      '{{entity.name}} requires a roster head.',
    'Single RDO Penalty': 'Single RDO Penalty',
    'Penalty Per Uncovered Shift': 'Penalty Per Uncovered Shift',
    'Duty Variation Penalty Per Hour': 'Duty Variation Penalty Per Hour',
    'Min RDOs Per Line': 'Min RDOs Per Line',
    'FAID Score': 'FAID Score',
    'Out of date': '(Out of date)',
    '{{train}} is too close to trains: {{others}}':
      '{{train}} is too close to trains: {{others}}',
    'Insufficient Headway': 'Insufficient Headway',
    '{{train}} passes too many trains: {{others}}':
      '{{train}} passes too many trains: {{others}}',
    'Too many passes': 'Too many passes',
    'Shift is assigned to multiple roster lines':
      'Shift is assigned to multiple roster lines',
    'Shift {{entity.shift.name}} {{entity.id}} is assigned to multiple roster lines [{{rosterLineNumbers}}]':
      'Shift {{entity.shift.name}} {{entity.id}} is assigned to multiple roster lines [{{rosterLineNumbers}}]',
    Shift: 'Shift',
    'Demand has invalid route': 'Demand has invalid route',
    'Demand ({{entity.id}}) has invalid route.':
      'Demand ({{entity.id}}) has invalid route.',
    "Unlocking this train will unlock the following demands' routes":
      "Unlocking this train will unlock the following demands' routes",
    'Locking this route will lock the following trains:':
      'Locking this route will lock the following trains:',
    'Locked Demand Route Has Unlocked Train':
      'Locked Demand Route Has Unlocked Train',
    'Demand {{entity.service.name}} requires trains [{{trains}}] to be locked':
      'Demand {{entity.service.name}} requires trains [{{trains}}] to be locked',
    'Rest Boundary': 'Rest Boundary',
    'Pre-rest Shift': 'Pre-rest Shift',
    'Post-rest Shift': 'Post-rest Shift',
    'Rest Duration': 'Rest Duration',
    'Rest Location': 'Rest Location',
    'Duration: {{duration}}': 'Duration: {{duration}}',
    '{{name}} - rest duration {{duration}}':
      '{{name}} - rest duration {{duration}}',
    'Select Rest Location': 'Select Rest Location',
    'Next Shift': 'Next Shift',
    'Select Next Shift': 'Select Next Shift',
    'Remote Rest for {{name}}': 'Remote Rest for {{name}}',
    'Remote Rest at {{location}}': 'Remote Rest at {{location}}',
    'Connect another shift with remote rest':
      'Connect another shift with remote rest',
    'Confirm deletion of remote rest': 'Confirm deletion of remote rest',
    'Cancel deletion of remote rest': 'Cancel deletion of remote rest',
    'Delete remote rest': 'Delete remote rest',
    'Crew Pool cannot have remote rests': 'Crew Pool cannot have remote rests',
    'Crew Pool has Type which does not allow remote rests':
      'Crew Pool has Type which does not allow remote rests',
    'Remote Rest too short': 'Remote Rest too short',
    '<0></0> should be at least {{remoteRestMinDurationHours}} hours long.':
      '<0></0> should be at least {{remoteRestMinDurationHours}} hours long.',
    'Duration: {{durationString}}': 'Duration: {{durationString}}',
    'Go to remote rest detail': 'Go to remote rest detail',
    '<0></0> occurs at <3></3> which is not configured for remote rests.':
      '<0></0> occurs at <3></3> which is not configured for remote rests.',
    '<0></0> occurs within <3></3> which is not configured for remote rests.':
      '<0></0> occurs within <3></3> which is not configured for remote rests.',
    'This shift and the one connected through its remote rest will be un-rostered.':
      'This shift and the one connected through its remote rest will be un-rostered.',
    'This sign on time will invalidate the remote rest at the start of the shift. If you proceed, the remote rest will be removed.':
      'This sign on time will invalidate the remote rest at the start of the shift. If you proceed, the remote rest will be removed.',
    'This sign off time will invalidate the remote rest at the end of the shift. If you proceed, the remote rest will be removed.':
      'This sign off time will invalidate the remote rest at the end of the shift. If you proceed, the remote rest will be removed.',
    'Remote Rest CrewPool mismatch': 'Remote Rest CrewPool mismatch',
    'Shifts and their connecting Remote Rest have mismatched CrewPools':
      'Shifts and their connecting Remote Rest have mismatched CrewPools',
    'this remote rest for {{poolName}}': 'this remote rest for {{poolName}}',
    'RosterLine and Shift have mismatched CrewPools':
      'RosterLine and Shift have mismatched CrewPools',
    Duty: 'Duty',
    'Yard Blocks': 'Yard Blocks',
    'Yard Block': 'Yard Block',
    Length: 'Length',
    Railcars: 'Railcars',
    'Number of Railcars': 'Number of Railcars',
    'Cannot have more than 7 starts per week':
      'Cannot have more than 7 starts per week',
    'Invalid driver assignment': 'Invalid driver assignment',
    'Could not find task associated with assignment {{entity.id}}.':
      'Could not find task associated with assignment {{entity.id}}.',
    'Driver task is not assigned to a shift':
      'Driver task is not assigned to a shift',
    '{{taskName}} for <2></2> is not assigned to a shift.':
      '{{taskName}} for <2></2> is not assigned to a shift.',
    'Train Blocks': 'Train Blocks',
    'Train Block': 'Train Block',
    'Set out': 'Set out',
    'Add Train Block': 'Add Train Block',
    'Removing a train block will unassign {{numYardBlocks}} yard block(s).':
      'Removing a train block will unassign {{numYardBlocks}} yard block(s).',
    'Miscellaneous Attachments': 'Miscellaneous Attachments',
    Blocks: 'Blocks',
    tonnageSymbol: 't',
    baseLengthSymbol: 'ft',
    'Number of starts': 'Number of starts',
    't per week': 't per week',
    'per week': 'per week',
    Locations: 'Locations',
    Misc: 'Misc',
    '(no yard blocks)': '(no yard blocks)',
    'Choose a train block whose route is compatible with this yard block.':
      'Choose a train block whose route is compatible with this yard block.',
    'Invalid yard block assignment at origin':
      'Invalid yard block assignment at origin',
    'Invalid yard block assignment at destination':
      'Invalid yard block assignment at destination',
    'Invalid yard block assignment': 'Invalid yard block assignment',
    "<0></0>'s origin is <3></3> but is being assigned to <6></6> at <9></9>.":
      "<0></0>'s origin is <3></3> but is being assigned to <6></6> at <9></9>.",
    "<0></0>'s destination is <3></3> but <5></5>'s set out location is <8></8>.":
      "<0></0>'s destination is <3></3> but <5></5>'s set out location is <8></8>.",
    'Yard Block Assignment (id={{entity.id}}) has a train block and departing leg which do not belong to the same train.':
      'Yard Block Assignment (id={{entity.id}}) has a train block and departing leg which do not belong to the same train.',
    'Unrouted yard block': 'Unrouted yard block',
    '<0></0> is not routed.': '<0></0> is not routed.',
    'Invalid yard block is assigned after train block set out':
      'Invalid yard block is assigned after train block set out',
    '<0></0> is assigned to leg {{departingLegNum}} of <7></7> which is after <11></11> is set out after arriving on leg {{arrivingLegNum}}.':
      '<0></0> is assigned to leg {{departingLegNum}} of <7></7> which is after <11></11> is set out after arriving on leg {{arrivingLegNum}}.',
    'Invalid dwell activity timing': 'Invalid dwell activity timing',
    'Train changeover not within dwell': 'Train changeover not within dwell',
    'Location changeover not within dwell':
      'Location changeover not within dwell',
    'Changeover at <1></1> between <4></4> and <7></7> is infeasible.':
      'Changeover at <1></1> between <4></4> and <7></7> is infeasible.',
    '<0></0> has infeasible changeover at <2></2>.':
      '<0></0> has infeasible changeover at <2></2>.',
    'Initial train starts': 'Initial train starts',
    Day: 'Day',
    Template: 'Template',
    'Train Templates': 'Train Templates',
    'Switch Train Starts': 'Switch Train Starts',
    'No loading tasks': 'No loading tasks',
    'Select the crew pool to auto-generate a roster for.':
      'Select the crew pool to auto-generate a roster for.',
    'This tells the engine how close it needs to get to the optimal solution before it is allowed to stop. Setting this number to be small will result in better solutions, but it will take longer to run. Setting this number high will result in a fast run time, but a less optimal solution. You will start to see diminishing returns for values < 5 %. Recommended: 5%':
      'This tells the engine how close it needs to get to the optimal solution before it is allowed to stop. Setting this number to be small will result in better solutions, but it will take longer to run. Setting this number high will result in a fast run time, but a less optimal solution. You will start to see diminishing returns for values < 5 %. Recommended: 5%',
    'The amount of time to spend inside the solver. Reccomended: 5 minutes.':
      'The amount of time to spend inside the solver. Reccomended: 5 minutes.',
    'Every single RDO placed on a line will incur this cost. This will encourage the engine to group RDOs and discourage single RDOs. This should always be set to a much smaller value than the cost of leaving a shift uncovered, otherwise the engine will prefer to leave shifts off the roster rather than add a single RDO to a line. Reccomended: 100':
      'Every single RDO placed on a line will incur this cost. This will encourage the engine to group RDOs and discourage single RDOs. This should always be set to a much smaller value than the cost of leaving a shift uncovered, otherwise the engine will prefer to leave shifts off the roster rather than add a single RDO to a line. Reccomended: 100',
    'Every shift that is not assigned to the roster will incur this cost. The main goal of the rostering engine is to cover all the work, so this should be set to the highest cost of all. Reccomended: 100,000':
      'Every shift that is not assigned to the roster will incur this cost. The main goal of the rostering engine is to cover all the work, so this should be set to the highest cost of all. Reccomended: 100,000',
    'This penalty is applied to a potential roster solution as a whole. The engine works out the line with the greatest density of work and also the line with the lowest density of work. It then applies the duty variation penalty to the difference between these two extremes - with the aim of producing a roster that has evenly distributed work across all lines. If this penalty is set to 0, the engine will not care about the distribution of work across lines - and is much faster. If the penalty is non-zero, the engine will work harder to come up with a solution with work nicely distributed evenly across all lines. The penalty is applied per hour, and so this parameter should be kept small (<= 50).':
      'This penalty is applied to a potential roster solution as a whole. The engine works out the line with the greatest density of work and also the line with the lowest density of work. It then applies the duty variation penalty to the difference between these two extremes - with the aim of producing a roster that has evenly distributed work across all lines. If this penalty is set to 0, the engine will not care about the distribution of work across lines - and is much faster. If the penalty is non-zero, the engine will work harder to come up with a solution with work nicely distributed evenly across all lines. The penalty is applied per hour, and so this parameter should be kept small (<= 50).',
    'Enforce that each line in the solution contains at least this many RDOs.':
      'Enforce that each line in the solution contains at least this many RDOs.',
    'Reset all parameters to their recommended settings.':
      'Reset all parameters to their recommended settings.',
    'Penalty Per Shift left uncovered': 'Penalty Per Shift left uncovered',
    'This penalty is applied for each shift that is chosen in the solution (in addition to the cost per hour associated with each crew type). This parameter is designed to give the engine extra incentive to reduce the total number of shifts and as a side effect, should reduce the number of short shifts you see in the solutions. It should be set high enough that the engine works hard to reduce the number of shifts but not too high that it drowns out all the other costs. Recommended: 1000':
      'This penalty is applied for each shift that is chosen in the solution (in addition to the cost per hour associated with each crew type). This parameter is designed to give the engine extra incentive to reduce the total number of shifts and as a side effect, should reduce the number of short shifts you see in the solutions. It should be set high enough that the engine works hard to reduce the number of shifts but not too high that it drowns out all the other costs. Recommended: 1000',
    'Each kilometer of travel in a car will incur this cost. This will drive the engine to reduce total car kilometers. Setting this value to a high number will drive the engine to produce solutions with not very much driving, while setting it low will mean cars are free to roam around the place as they please. Recommended: 10':
      'Each kilometer of travel in a car will incur this cost. This will drive the engine to reduce total car kilometers. Setting this value to a high number will drive the engine to produce solutions with not very much driving, while setting it low will mean cars are free to roam around the place as they please. Recommended: 10',
    'This cost is applied to a Driver shift if there is no opportunity for a meal break. The engine will still find a solution even if there are no meal break opportunities, but will incur the penalty for those shifts that don’t have one. Recommended: 100':
      'This cost is applied to a Driver shift if there is no opportunity for a meal break. The engine will still find a solution even if there are no meal break opportunities, but will incur the penalty for those shifts that don’t have one. Recommended: 100',
    'This parameter can be used to reduce the amount of switching between trains that happens during each shift. Recommended: 10':
      'This parameter can be used to reduce the amount of switching between trains that happens during each shift. Recommended: 10',
    'The shift engine is allowed to use changeovers that are shorter than the required changeover duration. Increasing this cost should reduce the number of short changeovers that are used as well as giving preference to changeovers that are close to the required duration over those that are very short. Recommended: 10':
      'The shift engine is allowed to use changeovers that are shorter than the required changeover duration. Increasing this cost should reduce the number of short changeovers that are used as well as giving preference to changeovers that are close to the required duration over those that are very short. Recommended: 10',
    'This cost is applied to every task that remains uncovered by a shift in the solution. It should be significantly more than the cost of a shift. Recommended: 10000':
      'This cost is applied to every task that remains uncovered by a shift in the solution. It should be significantly more than the cost of a shift. Recommended: 10000',
    'This cost is applied to LVs that are imbalanced in the solution. While it is important to discourage these, this shouldn’t be set so high that the engine would prefer to leave work uncovered. Recommended: 5000':
      'This cost is applied to LVs that are imbalanced in the solution. While it is important to discourage these, this shouldn’t be set so high that the engine would prefer to leave work uncovered. Recommended: 5000',
    'You may want to consider changeovers between trains that cross but do not have enough time for a changeover. This parameter allows the engine to introduce illegal changeovers that should be able to be fixed by running the pathing engine. Recommend: 0 or -30 minutes':
      'You may want to consider changeovers between trains that cross but do not have enough time for a changeover. This parameter allows the engine to introduce illegal changeovers that should be able to be fixed by running the pathing engine. Recommend: 0 or -30 minutes',
    'The user can provide a list of train Origin/Destination pairs for which the driver tasks should be crewed separately. For example, if RAI and DPO are used, all Cement shuttle driver tasks will be crewed by shifts that only contain other cement shuttle driver tasks.':
      'The user can provide a list of train Origin/Destination pairs for which the driver tasks should be crewed separately. For example, if RAI and DPO are used, all Cement shuttle driver tasks will be crewed by shifts that only contain other cement shuttle driver tasks.',
    'All compatible classes have been assigned':
      'All compatible classes have been assigned',
    Enable: 'Enable',
    Disable: 'Disable',
    'Train Starts': 'Train Starts',
    'Custom Task': 'Custom Task',
    'LV Task': 'LV Task',
    'Loading Assignment': 'Loading Assignment',
    'Shift Assignment': 'Shift Assignment',
    None: 'None',
    'Shunt duration might be out of date':
      'Shunt duration might be out of date',
    'The <1></1> {{kind}} shunt at <5></5> has changed since the duration override was set.':
      'The <1></1> {{kind}} shunt at <5></5> has changed since the duration override was set.',
    departure: 'departure',
    arrival: 'arrival',
    yardGraphHelpContentNewPan:
      '<0>To pan:</0><1><0>Shift</0> and <3>Scroll</3></1>',
    yardGraphHelpContentNewZoom:
      '<0>To zoom:</0><1><0>Ctrl</0> and <3>Scroll</3></1>',
    yardGraphHelpContentOldPan: 'To pan: <1>Click</1> and <4>Drag</4>',
    yardGraphHelpContentOldZoom: 'To zoom: <1>Scroll</1>',
    shiftGanttHelpContentNewPan:
      '<0>To pan:</0><1>Vertical: <1>Scroll</1></1><2>Horizontal: <1>Shift</1> and <4>Scroll</4></2>',
    shiftGanttHelpContentNewZoom:
      '<0>To zoom:</0><1><0>Ctrl</0> and <3>Scroll</3></1>',
    shiftGanttHelpContentOldPan: 'To pan: <1>Click</1> and <4>drag</4>',
    shiftGanttHelpContentOldZoom: 'To zoom: <1>Scroll</1>',
    trainGraphHelpContentNewPan:
      '<0>To pan:</0><1>Vertical: <1>Scroll</1></1><2>Horizontal: <1>Shift</1> and <4>Scroll</4></2>',
    trainGraphHelpContentNewZoom:
      '<0>To zoom:</0><1>Vertical:<1>Ctrl</1> and <4>Scroll</4></1><2>Horizontal:<1>Ctrl</1>+<3>Shift</3> and <6>Scroll</6></2>',
    trainGraphHelpContentOldPan: 'To pan: <1>Click</1> and <4>drag</4>',
    trainGraphHelpContentOldZoom: 'To zoom: <1>Scroll</1>',
    'delay changeover': 'delay changeover',
    'advance changeover': 'advance changeover',
    'Back to shift': 'Back to shift',
    'The Service Plan engine does not support parallel corridors and will not run when there are two or more corridors with the same two locations':
      'The Service Plan engine does not support parallel corridors and will not run when there are two or more corridors with the same two locations',
    'KCS Partial Import': 'KCS Partial Import',
    "<0></0>'s length of {{trainLength}}m exceeds the limit of {{maxLength}}m on corridor {{corridorName}}":
      "<0></0>'s length of {{trainLength}}m exceeds the limit of {{maxLength}}m on corridor {{corridorName}}",
    "<0></0>'s locomotive haulage capacity of {{haulageCapacity}}t is less than its trailing weight of {{tonnage}}t on corridor {{corridorName}}":
      "<0></0>'s locomotive haulage capacity of {{haulageCapacity}}t is less than its trailing weight of {{tonnage}}t on corridor {{corridorName}}",
    'Demand Template': 'Demand Template',
    'Demand does not run on any days': 'Demand does not run on any days',
    'Are you sure you want to delete {{num}} demand templates?':
      'Are you sure you want to delete {{num}} demand templates?',
  },
  'kcs-importer': {
    'Errors (Fix Before Saving):': 'Errors (Fix Before Saving):',
    Warnings: 'Warnings',
    'Train Schedule': 'Train Schedule',
    'Train Numbers': 'Train Numbers',
    'Day Priorities': 'Day Priorities',
    'Unscheduled Train Start Times': 'Unscheduled Train Start Times',
    'Combined Train Services': 'Combined Train Services',
    Interchanges: 'Interchanges',
    'Loco Assignments': 'Loco Assignments',
    'Traffic Profiles': 'Traffic Profiles',
    'Yard Block Segments': 'Yard Block Segments',
    'Freight Yard Blocks': 'Freight Yard Blocks',
    'Volume Forecast': 'Volume Forecast',
    'Upload data for {{name}}': 'Upload data for {{name}}',
    'Upload File': 'Upload File',
    'Import {{name}}': 'Import {{name}}',
    'Generate Schedules': 'Generate Schedules',
    'Save All Schedules': 'Save All Schedules',
    'Download Block Assignments': 'Download Block Assignments',
    'Schedule Creation Errors': 'Schedule Creation Errors',
    'Schedule Creation Warnings': 'Schedule Creation Warnings',
    '{{version}} Warnings': '{{version}} Warnings',
    'Please select file...': 'Please select file...',
    'Select the {{name}} file': 'Select the {{name}} file',
    'Import...': 'Import...',
    'Sheets required': 'Sheets required',
    'Upload and Generate': 'Upload and Generate',
    'Freight and Yard Blocks': 'Freight and Yard Blocks',
    'Freight Yard Block Snapshot': 'Freight Yard Block Snapshot',
    'Select a Freight Yard Block snapshot':
      'Select a Freight Yard Block snapshot',
    'Yard to Train Block Snapshot': 'Yard to Train Block Snapshot',
    'Select a Yard to Train Block Snapshot':
      'Select a Yard to Train Block Snapshot',
    'Train Schedule Snapshot': 'Train Schedule Snapshot',
    'Select a Train Schedule snapshot': 'Select a Train Schedule snapshot',
    'Enter a name for the schedules': 'Enter a name for the schedules',
    'Schedule saved successfully!': 'Schedule saved successfully!',
    Close: 'Close',
    'Train symbols {{entity}} appear twice in Train Numbers.':
      'Train symbols {{entity}} appear twice in Train Numbers.',
    'No Train Type in Scenario data.': 'No Train Type in Scenario data.',
    'Invalid border crossing for {{entity.description}}: Zero trains generated for {{entity.symbol}}, {{entity.carrier}}':
      'Invalid border crossing for {{entity.description}}: Zero trains generated for {{entity.symbol}}, {{entity.carrier}}',
    'Invalid border crossing for {{entity.description}}: Zero trains generated for {{entity.connectingSymbol}}, {{entity.connectingCarrier}}':
      'Invalid border crossing for {{entity.description}}: Zero trains generated for {{entity.connectingSymbol}}, {{entity.connectingCarrier}}',
    '{{entity.symbol}} not found for crossing {{entity.description}}.':
      '{{entity.symbol}} not found for crossing {{entity.description}}.',
    'Unequal number of trains for {{entity.description}}':
      'Unequal number of trains for {{entity.description}}',
    'Could not find {{entity}}': 'Could not find {{entity}}',
    '{{entity.collection}} should have unique {{entity.keyName}}. Duplicated values are:\n{{entity.values}}':
      '{{entity.collection}} should have unique {{entity.keyName}}. Duplicated values are:\n{{entity.values}}',
    'Expected {{entity.trainsPerWeek}} {{entity.symbol}} trains per week but created {{entity.created}}. Check that the day priorities and scheduled days are compatible.':
      'Expected {{entity.trainsPerWeek}} {{entity.symbol}} trains per week but created {{entity.created}}. Check that the day priorities and scheduled days are compatible.',
    'Number of trains to create for {{entity}} was rounded to zero.':
      'Number of trains to create for {{entity}} was rounded to zero.',
    'Different numbers of Trains for Match {{entity.symbol1}} ({{entity.count1}}) and {{entity.symbol2}} ({{entity.count2}})':
      'Different numbers of Trains for Match {{entity.symbol1}} ({{entity.count1}}) and {{entity.symbol2}} ({{entity.count2}})',
    'No corridor found between {{entity.loc1Name}} and {{entity.loc2Name}} for {{entity.description}}':
      'No corridor found between {{entity.loc1Name}} and {{entity.loc2Name}} for {{entity.description}}',
    'Inconsistent {{entity.kind}} throughout train {{entity.trainId}}.':
      'Inconsistent {{entity.kind}} throughout train {{entity.trainId}}.',
    'Corridor not found between stops {{entity.loc1Name}} and {{entity.loc2Name}} for train {{entity.trainId}}':
      'Corridor not found between stops {{entity.loc1Name}} and {{entity.loc2Name}} for train {{entity.trainId}}',
    'Invalid start time for {{entity.trainId}}. Unscheduled trains must start at midnight.':
      'Invalid start time for {{entity.trainId}}. Unscheduled trains must start at midnight.',
    'No train found to match with {{entity.trainId}} for {{entity.description}}':
      'No train found to match with {{entity.trainId}} for {{entity.description}}',
    'There was an error saving the schedules.':
      'There was an error saving the schedules.',
    'Unable to lock consists for symbol {{entity.symbol}}. To Location cannot be before From Location.':
      'Unable to lock consists for symbol {{entity.symbol}}. To Location cannot be before From Location.',
    'Missing Foreign Railroad': 'Missing Foreign Railroad',
    'Connecting dwell and/or travel time was unused for trains {{entity.description}} as they both connect in {{entity.location}}.':
      'Connecting dwell and/or travel time was unused for trains {{entity.description}} as they both connect in {{entity.location}}.',
    'Connecting dwell and/or travel time was not found for trains {{entity.startTrainSymbol}}, {{entity.startTrainCarrier}} & {{entity.endTrainSymbol}}, {{entity.endTrainCarrier}}':
      'Connecting dwell and/or travel time was not found for trains {{entity.startTrainSymbol}}, {{entity.startTrainCarrier}} & {{entity.endTrainSymbol}}, {{entity.endTrainCarrier}}',
    'Unable to lock consist of train {{entity.symbol}} as it cannot be found':
      'Unable to lock consist of train {{entity.symbol}} as it cannot be found',
    'Invalid lock - Location {{entity.stationName}}, {{entity.stationState}} not found in legs of train {{entity.name}}':
      'Invalid lock - Location {{entity.stationName}}, {{entity.stationState}} not found in legs of train {{entity.name}}',
    'Loco Event ({{entity.id}}) refers to a missing foreign railroad':
      'Loco Event ({{entity.id}}) refers to a missing foreign railroad',
    'Foreign owner {{entity.foreignRailroadName}} not found for {{entity.symbol}} assignment':
      'Foreign owner {{entity.foreignRailroadName}} not found for {{entity.symbol}} assignment',
    'Cannot join trains for {{entity.crossingDescription}} since the data used to create the trains is inconsistent. Input data for [{{entity.attributeList}}] must match.':
      'Cannot join trains for {{entity.crossingDescription}} since the data used to create the trains is inconsistent. Input data for [{{entity.attributeList}}] must match.',
    'No trains found for {{entity.description}} to combine.':
      'No trains found for {{entity.description}} to combine.',
    'Cannot join trains for symbol {{entity.symbol}} since the stops are not connected by a corridor.':
      'Cannot join trains for symbol {{entity.symbol}} since the stops are not connected by a corridor.',
    'Cannot join trains for {{entity.symbol}} because there are more than two carriers.':
      'Cannot join trains for {{entity.symbol}} because there are more than two carriers.',
    'Automatically joined trains for symbol {{entity.symbol}}.':
      'Automatically joined trains for symbol {{entity.symbol}}.',
    'Train Type': 'Train Type',
    'Business Group': 'Business Group',
    'Min Lead Locos': 'Min Lead Locos',
    'Max Hauled Locos': 'Max Hauled Locos',
    'Days of Week': 'Days of Week',
    'Train Starts': 'Train Starts',
    "Pick up {{entity.pickUpName}} and set out {{entity.setOutName}} for Yard Block {{entity.yardBlock.name}} and Train Block {{entity.trainBlock.trainBlockId}} are not part of train {{entity.train.combinedSymbol}}'s route through the known network, or are out of order.":
      "Pick up {{entity.pickUpName}} and set out {{entity.setOutName}} for Yard Block {{entity.yardBlock.name}} and Train Block {{entity.trainBlock.trainBlockId}} are not part of train {{entity.train.combinedSymbol}}'s route through the known network, or are out of order.",
    'Set out location {{entity.setOutLocation.name}} does not match other yardBlocks on trainBlock {{entity.trainBlockId}}':
      'Set out location {{entity.setOutLocation.name}} does not match other yardBlocks on trainBlock {{entity.trainBlockId}}',
    'Cannot find matching train {{entity.segment.trainSymbol}} for train block {{entity.segment.trainBlockId}}':
      'Cannot find matching train {{entity.segment.trainSymbol}} for train block {{entity.segment.trainBlockId}}',
    'Train {{entity.symbol}} has no matching train blocks':
      'Train {{entity.symbol}} has no matching train blocks',
    'Train {{entity.trainId}} requires non-zero Loads.':
      'Train {{entity.trainId}} requires non-zero Loads.',
    'Train {{entity.train}} requires non-zero Maximum Length and Maximum Tonnage.':
      'Train {{entity.train}} requires non-zero Maximum Length and Maximum Tonnage.',
    'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} has no matching volume forecasts':
      'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} has no matching volume forecasts',
    'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} is not routed correctly after {{entity.loc}}':
      'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} is not routed correctly after {{entity.loc}}',
    'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} is not fully routed between {{entity.loc1.name}} and its destination':
      'Yard block {{entity.yardBlockId}} from {{entity.yardBlockOrigin}} is not fully routed between {{entity.loc1.name}} and its destination',
    'Yard Block Segment refers to Yard Block {{entity.yardBlockID}} at {{entity.pickUpLocationName}} which has no freight.':
      'Yard Block Segment refers to Yard Block {{entity.yardBlockID}} at {{entity.pickUpLocationName}} which has no freight.',
    'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. Corridor found is not destined for the set out location, {{entity.locationName}}':
      'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. Corridor found is not destined for the set out location, {{entity.locationName}}',
    'Invalid Train Merge for {{entity.description}}. Check that the Train Symbols and Schedules exist.':
      'Invalid Train Merge for {{entity.description}}. Check that the Train Symbols and Schedules exist.',
    'Freight is not consistent throughout train with returned empties {{entity.trainSymbol}}.':
      'Freight is not consistent throughout train with returned empties {{entity.trainSymbol}}.',
    'Freight for {{entity.description}} is not routed properly after {{entity.locationName}}.':
      'Freight for {{entity.description}} is not routed properly after {{entity.locationName}}.',
    'Freight for {{entity.description}} reenters the known network at {{entity.locationName}} after previously departing.':
      'Freight for {{entity.description}} reenters the known network at {{entity.locationName}} after previously departing.',
    'Stops not found in train {{entity.trainId}} for corridor from {{entity.origin}} to {{entity.destination}}. Check for inconsistent stops between Train Instances.':
      'Stops not found in train {{entity.trainId}} for corridor from {{entity.origin}} to {{entity.destination}}. Check for inconsistent stops between Train Instances.',
    'Train (or combined train) for {{entity.symbol}} travels over the corridor from {{entity.origin}} to {{entity.destination}} in the same direction twice.':
      'Train (or combined train) for {{entity.symbol}} travels over the corridor from {{entity.origin}} to {{entity.destination}} in the same direction twice.',
    'Loco Assignment for Train {{entity.symbol}} is attached or detached on corridor {{entity.origin}} to {{entity.destination}} which is not in the journey.':
      'Loco Assignment for Train {{entity.symbol}} is attached or detached on corridor {{entity.origin}} to {{entity.destination}} which is not in the journey.',
    'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. {{entity.symbol}} arrives at {{entity.locationName}} multiple times':
      'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. {{entity.symbol}} arrives at {{entity.locationName}} multiple times',
    'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. {{entity.symbol}} never arrives at {{entity.locationName}}':
      'Unable to attach train block {{entity.trainBlockId}} to {{entity.symbol}}. {{entity.symbol}} never arrives at {{entity.locationName}}',
    'Stops for train {{entity.trainId}} are not consistent across train instances.':
      'Stops for train {{entity.trainId}} are not consistent across train instances.',
    'Loco Assignment for Train {{entity.symbol}} is attached or detached at {{entity.location}} which is not a yard':
      'Loco Assignment for Train {{entity.symbol}} is attached or detached at {{entity.location}} which is not a yard',
    'Loco Assignment for Train {{entity.symbol}} from corridor {{entity.origin}} to corridor {{entity.destination}} is detached before it is attached.':
      'Loco Assignment for Train {{entity.symbol}} from corridor {{entity.origin}} to corridor {{entity.destination}} is detached before it is attached.',
    'Freight for {{entity.description}} travelling on yard block {{entity.yardBlockID}} has the same location {{entity.locationName}} for pick up and set out. This freight will not be routed':
      'Freight for {{entity.description}} travelling on yard block {{entity.yardBlockID}} has the same location {{entity.locationName}} for pick up and set out. This freight will not be routed',
    'MCS returned an unexpected response. {{error}}':
      'MCS returned an unexpected response. {{error}}',
    'There was an error processing the data from MCS':
      'There was an error processing the data from MCS',
    'There was an error saving the snapshot. Please try again':
      'There was an error saving the snapshot. Please try again',
    'No freight found on {{entity.trainSymbol}}, could not match freight services':
      'No freight found on {{entity.trainSymbol}}, could not match freight services',
    '{{version}} will be created with {{trainCount}} trains, {{legCount}} legs, {{yardBlockCount}} yard blocks and {{trainBlocksCount}} train blocks':
      '{{version}} will be created with {{trainCount}} trains, {{legCount}} legs, {{yardBlockCount}} yard blocks and {{trainBlocksCount}} train blocks',
    'There was an unexpected error': 'There was an unexpected error',
    '{{entity.symbol}} not found in Train Symbols or previous combined symbols':
      '{{entity.symbol}} not found in Train Symbols or previous combined symbols',
    'Symbol and connecting symbol are the same for combined service row {{entity.symbol}}. A train symbol cannot be combined with itself':
      'Symbol and connecting symbol are the same for combined service row {{entity.symbol}}. A train symbol cannot be combined with itself',
    'Freight for {{entity.description}} loops back on itself at {{entity.locationName}}':
      'Freight for {{entity.description}} loops back on itself at {{entity.locationName}}',
    'Loco Assignment references Train {{entity.symbol}} that does not exist':
      'Loco Assignment references Train {{entity.symbol}} that does not exist',
    'Yard Block {{entity.name}} from {{entity.origin.name}} to {{entity.destination.name}} has no Yard Block Segment.':
      'Yard Block {{entity.name}} from {{entity.origin.name}} to {{entity.destination.name}} has no Yard Block Segment.',
    'Skipping {{entity.symbol}} because its Unscheduled Train Timing was missing.':
      'Skipping {{entity.symbol}} because its Unscheduled Train Timing was missing.',
    Details: 'Details',
    'It looks like the scenario has changed since this plan was last opened. The plan is now being updated to conform to the scenario.':
      'It looks like the scenario has changed since this plan was last opened. The plan is now being updated to conform to the scenario.',
    'Demand Instances': 'Demand Instances',
  },
}
