import React from 'react'

interface Props {
  hasErrors: boolean
  load: (documentId: string) => Promise<void>
  error: (r: any) => void
  setupSave: () => void
  loaded: boolean
  loadedId: number
  documentId: string
  enableSave?: boolean
  fallback?: React.ReactNode
  children: React.ReactNode
}

export class DocumentLoader extends React.Component<Props> {
  static displayName = 'DocumentLoader'
  static defaultProps = {
    fallback: null as React.ReactNode,
  }

  componentDidMount() {
    return this.load()
  }

  componentDidUpdate() {
    if (
      this.props.loadedId &&
      this.props.loadedId !== parseInt(this.props.documentId, 10)
    ) {
      window.location.reload()
    }
  }

  load() {
    const { enableSave, documentId } = this.props
    return this.props
      .load(documentId)
      .then(() => {
        if (enableSave) {
          this.props.setupSave()
        }
      })
      .catch(response => this.props.error(response))
  }

  render() {
    const { loaded, hasErrors, fallback, children } = this.props
    return loaded ? !hasErrors && children : fallback
  }
}
