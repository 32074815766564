import { createSelector, createStructuredSelector } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import {
  ScenarioDocument,
  ScenarioCollections,
  ScenarioSingletons,
} from 'src/service-design/scenario/document/types'
// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import {
  ServiceDesignDocument,
  ServiceDesignCollections,
  ServiceDesignSingletons,
} from 'src/service-design/sd-plan/document/types'

// TODO: These two types are pretty concerning as it implies that
// every repo / factory must depend on precisely the same state.
// This means that if you want a model that depends on fatigue scores (for
// example) then all the models will rely on it.
export type CollectionData = ServiceDesignCollections & ScenarioCollections
export type DocumentData = CollectionData & {
  singletons: ServiceDesignSingletons & ScenarioSingletons
}

export type FactoryInput = {
  collectionData: DocumentData
}

export type RootState = {
  documents: {
    scenario?: {
      data: ScenarioDocument
    }
    'service-design'?: {
      data: ServiceDesignDocument
    }
  }
}

const EMPTY_DOC = { singletons: null as Record<string, any> }
export const getScenarioData = (state: RootState): ScenarioDocument =>
  state.documents.hasOwnProperty('scenario')
    ? state.documents.scenario.data
    : (EMPTY_DOC as ScenarioDocument)

const getServiceDesignData = (state: RootState): ServiceDesignDocument =>
  state.documents.hasOwnProperty('service-design')
    ? state.documents['service-design'].data
    : (EMPTY_DOC as ServiceDesignDocument)

export const getCollectionData = createSelector(
  getScenarioData,
  getServiceDesignData,
  (scenario, plan) => ({
    ...scenario,
    ...plan,
    singletons: { ...scenario.singletons, ...plan.singletons },
  }),
)

// TODO this currently gets all the things and is used in scenario and service-design
export const getFactoryInput = createStructuredSelector<
  RootState,
  {
    collectionData: ReturnType<typeof getCollectionData>
  }
>({
  collectionData: getCollectionData,
})

export type FactoryInputParameters = Parameters<typeof getFactoryInput>[0]
