export function isNotNull<T>(a: T | null): a is T {
  return a !== null
}

export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export function assertNever(x: never): never {
  throw new Error('Unreachable code reached')
}
