import { createAction } from 'typesafe-actions'

import { IDocumentRevision } from 'src/core/types/document'
import * as constants from 'src/service-design/shared/constants'

// NOTE: This is split out so that it can be used in src/service-design/traingraph/infra/redux/document.ts, without everything breaking
// Importing from src/service-design/shared/document/actions/index.ts was causing keycloak-js to be included in the workers. Which would fail because keycloak-js wants a `window` global

export const revisionsReceive = createAction(
  constants.REVISIONS_RECEIVE,
  (revs: IDocumentRevision[]) => revs,
)()
