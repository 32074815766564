import { create as jsonDiffCreate } from 'jsondiffpatch'
import { get } from 'lodash'
import { createSelector } from 'reselect'

import { IDocumentRevision } from 'src/core/types/document'

export const getCurrentRevision = (state: any) =>
  get(state, 'save.currentRevision')
export const documentSaving = (state: any) => get(state, 'save.saving', false)
export const documentSaveStopped = (state: any) =>
  get(state, 'save.saveStopped', false)

type SaveSelector = (state: any) => IDocumentRevision<any>

// TODO this is a hack - there is an implication that there is only one active
// (mutable) document at a time which used to be identified by documentRoot
let saveSelector: SaveSelector = null

// must return a backend document ie. {meta: {}, data: {}}
export const setSaveSelector = (selector: SaveSelector) => {
  saveSelector = selector
}

// This exists for test purposes only
export const resetSaveSelector = () => {
  saveSelector = undefined
}

export const getSaveSelector = (): SaveSelector => {
  if (saveSelector === null) {
    throw new Error('SAVE SELECTOR NOT SET WUT')
  }
  return saveSelector
}

// Diffing on 2 long strings uses a separate text diffing algorithm that outputs a unidiff format.
// Unidiff isn't supported in jsonpatch spec. Need to set min character limit higher, or else the formatter will fail to parse the format.
// (see BOSS-2021)
const jsonDiffPatch = jsonDiffCreate({
  textDiff: {
    minLength: Number.MAX_SAFE_INTEGER,
  },
})

export const getDocumentDiff = createSelector(
  state => getSaveSelector()(state),
  getCurrentRevision,
  (document, revision) =>
    document && jsonDiffPatch.diff(revision, document.data),
)

export const documentModified = createSelector(getDocumentDiff, diff =>
  Boolean(diff),
)
