import { createAction, ActionType } from 'typesafe-actions'

export const activateInlineField = createAction(
  'ACTIVATE_INLINE_FIELD',
)<string>()

export const deactivateInlineField = createAction('DEACTIVATE_INLINE_FIELD')()

export const inlineFieldActions = {
  activateInlineField,
  deactivateInlineField,
} as const

export type InlineFieldActionType = ActionType<typeof inlineFieldActions>
