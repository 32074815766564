import styled from 'styled-components'

import { Box } from 'src/core/ui/Box'
import { lineRems } from 'src/core/ui/calcs'

export type ListProps = {
  space?: number
  rowSpace?: number
  columnSpace?: number
}

const calcGap = ({ space = 0, rowSpace = 0.5, columnSpace = 1 }: ListProps) =>
  space ? lineRems(space) : `${lineRems(rowSpace)} ${lineRems(columnSpace)}`

/**
 * Displays a set of key-value pairs, or titles and details for the less
 * progamming-inclined. Useful for detail panels and whatnot without having to
 * wrangle tables or your own custom css grid. Titles are all right aligned,
 * and details left aligned.
 *
 * Spacing between rows and columns can be controlled with the `space` prop, or
 * individual axes can be controlled with the `rowSpace` and `columnSpace`
 * props.
 *
 * `List`, as well as `Title` and `Detail` all extend the Box component, and as
 * such can utilize it's padding props
 */
export const List = styled(Box).attrs({ as: 'dl' })<ListProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0;
  grid-gap: ${calcGap};
`

export const Title = styled(Box).attrs({ as: 'dt' })`
  text-align: right;
  font-weight: bold;
`

export const Detail = styled(Box).attrs({ as: 'dd' })`
  margin-left: 0;
`
