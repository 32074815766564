import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import i18n from 'src/i18n'

import * as constants from 'src/service-design/shared/constants'
import {
  postDocumentLoadDisplayModalError,
  setupSave,
} from 'src/service-design/shared/document/actions'
import { documentsLoad } from 'src/service-design/shared/document/actions/api'
import { getSaveSelector } from 'src/service-design/shared/document/selectors/save'
import { DocumentSpec } from 'src/service-design/shared/document/types'
import { modalShow } from 'src/service-design/shared/modals/actions'

import { DocumentLoader as DocumentLoaderComp } from './component'

type ExtraAction =
  | ((documentId: string) => AnyAction)
  | ((documentId: string) => (dispatch: Dispatch<any>) => Promise<void>)

interface Props {
  enableSave: boolean
  documentSpec: DocumentSpec<any>
  extraActions?: ExtraAction[]
}

const mapStateToProps = (state: any, { enableSave }: Props) => {
  const document = enableSave && getSaveSelector()(state)
  return {
    loaded: state.loading.loaded,
    hasErrors: state.loading.hasErrors,
    loadedId: document && document.meta.id,
  }
}

const mapDispatchToProps = (
  dispatch: any,
  { documentSpec, enableSave, extraActions = [] as ExtraAction[] }: Props,
) => ({
  setupSave: () => dispatch(setupSave()),
  load: (id: string) =>
    Promise.all([
      dispatch(documentsLoad(id)),
      ...extraActions.map(a => dispatch(a(id))),
    ]).then(() => {
      dispatch(
        postDocumentLoadDisplayModalError({
          documentSpec,
          enableSave,
        }),
      )
    }),
  error: (response: any) =>
    dispatch(
      modalShow(constants.MODAL_ERROR, {
        header: 'There was an error loading the document.',
        message: `${i18n.t('Status Code')} ${response.status}: ${
          response.data
        }`,
      }),
    ),
})

export const DocumentLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentLoaderComp)
