import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const documentsExportSelector = (state: {
  ui: { documentsExport: { loading: boolean } }
}) => state.ui.documentsExport.loading

const StyledWrapper = styled(Segment)`
  &,
  &.segment {
    height: 100%;
    border: none;
  }

  &.fixed {
    position: static;
  }
`

type LoadingSpinnerProps = {
  fixed?: boolean
  className?: string
  content?: string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = React.memo(
  ({ fixed = false, className, content = 'Loading' }) => (
    <StyledWrapper className={classNames({ fixed }, className)}>
      <Dimmer active inverted>
        <Loader inverted content={content} />
      </Dimmer>
    </StyledWrapper>
  ),
)

export const FullWidthSpinner = styled(LoadingSpinner)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FullPageSpinner = styled(FullWidthSpinner)`
  &&& {
    position: fixed;
    opacity: 0.7;
    margin-top: 0;
  }
`

export const ExportSpinner = () => {
  const waiting = useSelector(documentsExportSelector)
  return waiting && <FullPageSpinner content="Exporting" />
}
