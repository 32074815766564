export default {
  common: {
    /*
    Insert translations here
    */
  },
  'service-design': {
    baseLengthSymbol: 'm',
    Wagons: 'Wagons',
    wagons: 'wagons',
  },
}
