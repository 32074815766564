import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const UnStyledNavLink = styled(NavLink)`
  color: currentColor;

  &:hover {
    color: currentColor;
  }
`
