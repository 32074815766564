import styled from 'styled-components'

import { lineRems, baseLine } from './calcs'

export type Props = {
  padding?: number
  rowPadding?: number
  columnPadding?: number
}

const calcPadding = ({ padding, rowPadding, columnPadding }: Props) =>
  padding != null
    ? lineRems(padding)
    : `${lineRems(columnPadding ?? 0)} ${lineRems(rowPadding ?? 0)}`

/**
 * See src/storeis/Box.stories.mdx for usage
 */
export const Box = styled.div.attrs<Props>(
  ({ padding, rowPadding, columnPadding, style }) => ({
    style:
      padding ?? rowPadding ?? columnPadding
        ? {
            '--padding': calcPadding({ padding, rowPadding, columnPadding }),
            ...style,
          }
        : style,
  }),
)<Props>`
  /* Default for when not set, to prevent inheritance of value from parents.
   * Any 'style' attribute will override this */
  --padding: 0;

  font-size: 14px;
  line-height: ${baseLine};
  padding: var(--padding);
`
