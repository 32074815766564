import { Relationships } from 'src/service-design/shared/document/relationships'

import { BrokenRelationship } from './objects'

export const findBrokenRelations = <T extends Record<string, { id: string }[]>>(
  relationships: Relationships<{ [P in keyof T]: T[P][0] }>,
  collectionData: T,
) => {
  const problems = []
  for (const collectionName of relationships.collections) {
    for (const relation of relationships.getRels(collectionName)) {
      const targetIds = new Set(
        (collectionData[relation.collection] as any[]).map(x => x.id),
      )

      for (const obj of collectionData[collectionName] || []) {
        // @ts-ignore type-safe is a nice idea
        const foreignValue = obj[relation.foreign]
        const isInvalid = foreignValue !== null && !targetIds.has(foreignValue)
        if (isInvalid) {
          problems.push(
            new BrokenRelationship({
              // @ts-ignore
              collectionName,
              affectedObj: obj,
              relationDefinition: relation,
            }),
          )
        }
      }
    }
  }
  return problems
}
