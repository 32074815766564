import { Duration as LuxonDuration } from 'luxon'

import * as domainConstants from 'src/service-design/core/domain/constants'

import { Delta } from './delta'
import { Duration } from './duration'
import { EpochTime } from './epoch-time'

export { CyclicTime } from './cyclic-time'
export { Interval } from './interval'
export { TimeOfDay } from './time-of-day'
export { TZTime } from './tz-time'
export { Duration, Delta, EpochTime }

export const DAY_DURATION = Duration.fromSeconds(
  domainConstants.SECONDS_PER_DAY,
)
export const DAY_DELTA = Delta.fromSeconds(domainConstants.SECONDS_PER_DAY)
export const WEEK_DURATION = Duration.fromSeconds(
  domainConstants.SECONDS_PER_WEEK,
)
export const WEEK_DELTA = Delta.fromSeconds(domainConstants.SECONDS_PER_WEEK)
export const END_OF_WEEK_EPOCH_TIME = EpochTime.fromSeconds(
  domainConstants.SECONDS_PER_WEEK,
)

const zeroPad = (value: number, length: number): string =>
  `${value}`.padStart(length, '00')

const hoursMinutes = (seconds: number, unwrapped = false): number[] => {
  // converts number of seconds to a number of minutes and the total hours
  // number of hours is not normalized to be under 24.
  const secondsOffset = unwrapped
    ? seconds
    : seconds % domainConstants.SECONDS_PER_ROSTER
  const s = secondsOffset % domainConstants.SECONDS_PER_MINUTE

  const minutes = (secondsOffset - s) / domainConstants.SECONDS_PER_MINUTE
  const m = minutes % domainConstants.MINUTES_PER_HOUR

  const hours = (minutes - m) / domainConstants.MINUTES_PER_HOUR
  return [hours, m]
}

// This really doesn't belong here but it will do for now:
//
// Note the % operator in Javascript is actually a remainder operator
// *NOT* a modulo operator. The main difference is the treatment of negative
// numbers.
//
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder
export const modulo = (x: number, base: number) => ((x % base) + base) % base

export const normalize = (seconds: number): number =>
  modulo(seconds, domainConstants.SECONDS_PER_ROSTER)

export const offsetTime = (offset: number): string => {
  const [hours, m] = hoursMinutes(normalize(offset))
  const h = hours % domainConstants.HOURS_PER_DAY

  const pm = zeroPad(m, 2)
  const ph = zeroPad(h, 2)

  return `${ph}:${pm}`
}

export const offsetString = (offset: number): string => {
  const [hours, m] = hoursMinutes(normalize(offset))
  const h = hours % domainConstants.HOURS_PER_DAY

  const d = (hours - h) / domainConstants.HOURS_PER_DAY

  const pm = zeroPad(m, 2)
  const ph = zeroPad(h, 2)
  const days = domainConstants.DAYS
  return `${days[d]} ${ph}:${pm}`
}

export const durationString = (
  seconds: number,
  space: boolean = false,
): string => {
  const [h, m] = hoursMinutes(Math.abs(seconds), true)
  return `${seconds < 0 ? '-' : ''}${zeroPad(h, 2)}h${
    space ? ' ' : ''
  }${zeroPad(m, 2)}m`
}

export const timeOfWeekToDayAndTime = (
  offset: number,
): { dayOffset: number; time: LuxonDuration } => {
  const duration = LuxonDuration.fromObject({ seconds: offset })
  const dayOffset = parseInt(
    LuxonDuration.fromObject({ seconds: offset }).toFormat('d'),
    10,
  )
  const time = duration.minus({ days: dayOffset }).normalize()

  return { dayOffset, time }
}

export const timeOfDayString = (offset: number): string => {
  const { time } = timeOfWeekToDayAndTime(offset)
  return time.toFormat('hh:mm')
}

export const dayOffsetString = (offset: number): string => {
  const { dayOffset } = timeOfWeekToDayAndTime(offset)

  if (dayOffset > 0) {
    return `+${dayOffset}d`
  }
  if (dayOffset < 0) {
    return `${dayOffset}d`
  }

  return ''
}

export const intersectionExclusive = (
  [t1Start, t1End]: [number, number],
  [t2Start, t2End]: [number, number],
): boolean => t1Start < t2End && t1End > t2Start

export const intersectionExclusiveWithNegative = (
  t1: [number, number],
  [t2Start, t2End]: [number, number],
): boolean =>
  intersectionExclusive(
    t1,
    t2Start > t2End ? [t2End, t2Start] : [t2Start, t2End],
  )

export const intersectionInclusive = (
  [t1Start, t1End]: [number, number],
  [t2Start, t2End]: [number, number],
): boolean => t1Start <= t2End && t1End >= t2Start

export const toUtc = (offset: number, timezoneOffset: number): number =>
  offset - timezoneOffset

export const snapTo = (normalized: number, near: number): number =>
  Math.round((near - normalized) / domainConstants.SECONDS_PER_WEEK) *
    domainConstants.SECONDS_PER_WEEK +
  normalized

export const timeToDelta = (
  initialDelta: number,
  initialTime: number,
  newTime: number = initialTime,
): number => {
  const boundedTime = snapTo(newTime, initialTime - initialDelta)
  return initialDelta + (boundedTime - initialTime)
}

export const inBoundsUnWrapped = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => tStart >= boundStart && tEnd <= boundEnd

export const inBounds = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => {
  let normbStart = normalize(boundStart)
  let normbEnd = boundEnd

  const normtStart = normalize(tStart)
  let normtEnd = tEnd

  // Bound defined as in next week
  if (
    boundStart >= domainConstants.SECONDS_PER_WEEK &&
    boundEnd >= domainConstants.SECONDS_PER_WEEK
  ) {
    normbEnd = normalize(boundEnd)
  }

  // task defined as in next week
  if (
    tStart >= domainConstants.SECONDS_PER_WEEK &&
    tEnd >= domainConstants.SECONDS_PER_WEEK
  ) {
    normtEnd = normalize(tEnd)
  }

  // Is crossing over the week boundary
  if (
    boundStart < domainConstants.SECONDS_PER_WEEK &&
    boundEnd > domainConstants.SECONDS_PER_WEEK
  ) {
    const result = inBoundsUnWrapped(
      [normbStart, normbEnd],
      [normtStart, normtEnd],
    )

    if (result) {
      return true
    }

    return inBoundsUnWrapped(
      [normbStart, normbEnd],
      [
        normtStart + domainConstants.SECONDS_PER_WEEK,
        normtEnd + domainConstants.SECONDS_PER_WEEK,
      ],
    )
  }

  if (normbStart > normbEnd) {
    normbStart -= domainConstants.SECONDS_PER_WEEK
  }

  return inBoundsUnWrapped([normbStart, normbEnd], [normtStart, normtEnd])
}

export const roundToMinute = (seconds: number): number =>
  Math.round(seconds / domainConstants.SECONDS_PER_MINUTE) *
  domainConstants.SECONDS_PER_MINUTE

export function wrappedDuration(
  startTime: number,
  endTime: number,
  lengthOfWeek: number = domainConstants.SECONDS_PER_ROSTER,
): number {
  if (startTime > endTime) {
    return endTime + lengthOfWeek - startTime
  }

  return endTime - startTime
}

export const secondsToTimeOfDay = (
  seconds: number,
): { time: number; day: number } => {
  if (Number.isInteger(seconds)) {
    const time = seconds % domainConstants.SECONDS_PER_DAY
    const dayPart = seconds - time

    const day = dayPart / domainConstants.SECONDS_PER_DAY
    return { time, day }
  }
  return null
}

export const dayTimeToSeconds = (day: number, time: number): number => {
  if (!Number.isInteger(day) || !Number.isInteger(time)) {
    return undefined
  }

  return domainConstants.SECONDS_PER_DAY * day + time
}

export const inBoundsOfDay = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => {
  const { day } = secondsToTimeOfDay(tStart)

  const offsetBoundStart = dayTimeToSeconds(day, boundStart)
  let offsetBoundEnd = dayTimeToSeconds(day, boundEnd)

  if (boundEnd < boundStart) {
    offsetBoundEnd = dayTimeToSeconds(day + 1, boundEnd)
  }

  return inBoundsUnWrapped([offsetBoundStart, offsetBoundEnd], [tStart, tEnd])
}
