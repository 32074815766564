export const BASE_DATE = '2017-01-01T00:00Z'
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const
export const DAY_LETTERS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'] as const

export const LONG_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const

export const DAYS_IN_WEEK = DAYS.length
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const SECONDS_PER_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE
export const HOURS_PER_DAY = 24
export const WEEKS_PER_YEAR = 52
export const HOURS_PER_WEEK = HOURS_PER_DAY * DAYS_IN_WEEK
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR
export const SECONDS_PER_WEEK = DAYS_IN_WEEK * SECONDS_PER_DAY
export const SECONDS_PER_ROSTER = SECONDS_PER_WEEK
