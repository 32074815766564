import { Delta } from 'src/service-design/core/domain/dates/delta'
import { Duration } from 'src/service-design/core/domain/dates/duration'
import { Location } from 'src/service-design/shared/models/location'

import { CyclicTime } from './cyclic-time'
import { EpochTime } from './epoch-time'

export class TZTime {
  private constructor(
    private readonly _seconds: number,
    private readonly _tzoffset: number,
  ) {}

  static fromUtcSeconds(seconds: number, tzoffset: number) {
    return new TZTime(seconds, tzoffset)
  }

  static fromLocalSeconds(seconds: number, tzoffset: number) {
    return new TZTime(seconds - tzoffset, tzoffset)
  }

  static fromEpochTime(time: EpochTime, location: Location) {
    return TZTime.fromUtcSeconds(time.toSeconds(), location.timezone.offset)
  }

  static sortComparator(t1: TZTime, t2: TZTime) {
    return t1._seconds - t2._seconds
  }

  asEpochTime() {
    return EpochTime.fromSeconds(this._seconds)
  }

  asCyclicTime() {
    return CyclicTime.fromSeconds(this.toSeconds())
  }

  /**
   * Makes the TZTime later by the Duration/Delta provided
   *
   * @returns new later TZTime
   */
  makeLater(toAdd: Duration | Delta): TZTime {
    return new TZTime(this._seconds + toAdd.toSeconds(), this._tzoffset)
  }

  /**
   * Makes the TZTime earlier by the Duration/Delta provided
   * @returns new delayed TZTime
   */
  makeEarlier(toSubtract: Duration | Delta): TZTime {
    return new TZTime(this._seconds - toSubtract.toSeconds(), this._tzoffset)
  }

  /**
   * Returns if this TZTime is earlier in time
   * than the `other` TZTime
   *
   * If they are equal the result is true
   * @param other another TZTime
   *
   * @returns whether this is earlier or equal
   */
  isEarlier(other: TZTime): boolean {
    return this._seconds <= other._seconds
  }

  /**
   * Returns if this TZTime is earlier in time
   * than the `other` TZTime
   *
   * If they are equal the result is false
   * @param other another TZTime
   *
   * @returns whether this is earlier and not equal
   */
  isStrictlyEarlier(other: TZTime): boolean {
    return this._seconds < other._seconds
  }

  /**
   * Returns if this TZTime is later in time
   * than the `other` TZTime
   *
   * If they are equal the result is true
   * @param other another TZTime
   *
   * @returns whether this is later or equal
   */
  isLater(other: TZTime): boolean {
    return this._seconds >= other._seconds
  }

  /**
   * Returns if this TZTime is later in time
   * than the `other` TZTime
   *
   * If they are equal the result is false
   * @param other another TZTime
   *
   * @returns whether this is later and not equal
   */
  isStrictlyLater(other: TZTime): boolean {
    return this._seconds > other._seconds
  }

  toSeconds(): number {
    return this._seconds + this._tzoffset
  }
}
