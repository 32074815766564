export const DOCUMENT_ERRORS_UPDATE = 'DOCUMENT_ERRORS_UPDATE'

export const INSTANCE_ADD = 'INSTANCE_ADD'
export const INSTANCE_EDIT = 'INSTANCE_EDIT'
export const INSTANCE_PATCH = 'INSTANCE_PATCH'
export const BATCH_PATCH = 'BATCH_PATCH'
export const SINGLETON_EDIT = 'SINGLETON_EDIT'
export const INSTANCE_DELETE = 'INSTANCE_DELETE'

export const LINKED_INSTANCE_INSERT = 'LINKED_INSTANCE_INSERT'
export const LINKED_INSTANCE_DELETE = 'LINKED_INSTANCE_DELETE'

export const LOCK_TRAIN = 'Train Lock'
export const LOCK_WORKING_LOCO = 'Working Loco Lock'

export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_DISMISS = 'MODAL_DISMISS'
// modals
export const MODAL_BLOCKING_ERROR = 'MODAL_BLOCKING_ERROR'
export const MODAL_ACKABLE_ERROR = 'MODAL_ACKABLE_ERROR'
export const MODAL_DOCUMENT_ARCHIVE = 'MODAL_DOCUMENT_ARCHIVE'
export const MODAL_DOCUMENT_COPY = 'MODAL_DOCUMENT_COPY'
export const MODAL_DOCUMENT_RENAME = 'MODAL_DOCUMENT_RENAME'
export const MODAL_DOCUMENT_PROPERTIES = 'MODAL_DOCUMENT_PROPERTIES'
export const MODAL_DOCUMENT_UNARCHIVE = 'MODAL_DOCUMENT_UNARCHIVE'
export const MODAL_ERROR = 'MODAL_ERROR'

export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED'

export const REVISIONS_RECEIVE = 'REVISIONS_RECEIVE'

export const SAVE_INTERVAL = 5000
export const SAVE_RETRY_ATTEMPTS = 5
export const SAVE_DEFAULT_ERROR = '[ERR:DEFAULT_SAVE]'
export const SAVE_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR]'
export const SAVE_CONNECTION_ERROR_STATUS = 0

export const FAID_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR_FAID]'

export const KEYCODE_ENTER = 13
