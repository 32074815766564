import classnames from 'classnames'
import styled from 'styled-components'

import { Box } from './Box'
import { lineRems } from './calcs'

type Props = {
  direction?: 'horizontal' | 'vertical'
  align?: 'start' | 'middle' | 'end'
  justify?: 'start' | 'middle' | 'end'
  space?: number
  noMargins?: boolean
  className?: string
  fluid?: boolean
  scroll?: boolean
}

export const Stack = styled(Box).attrs<Props>(
  ({
    className,
    direction,
    noMargins,
    fluid,
    align,
    justify,
    space,
    scroll,
    style,
  }) => ({
    className: classnames(direction, { noMargins, fluid, scroll }, className),
    style: {
      '--space': lineRems(space ?? 0),
      '--align': align === 'middle' ? 'center' : align,
      '--justify': justify === 'middle' ? 'center' : justify,
      ...style,
    },
  }),
)<Props>`
  --space: 0;
  --align: default;
  --justify: default;

  display: grid;
  grid-gap: var(--space, 0);

  &.horizontal {
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: var(--align);
    justify-content: var(--justify);
  }

  &.vertical {
    grid-auto-flow: row;
    grid-auto-rows: minmax(auto, min-content);
    grid-auto-columns: minmax(auto, max-content);
    justify-content: var(--align);
    align-items: var(--justify);
    overflow: hidden;

    &.scroll {
      grid-auto-rows: max-content;
      overflow: auto;
    }
  }

  &.fluid {
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  &&&.noMargins > * {
    margin: 0;
  }
`

Stack.defaultProps = {
  direction: 'vertical',
  noMargins: false,
}
