export class Delta {
  /**
   * Constant `Delta` with no offset
   */
  static readonly nil = new Delta(0)

  constructor(private readonly _seconds: number) {}

  /**
   *  Constructs a `Delta` from a seconds representation
   */
  static fromSeconds(seconds: number) {
    return new Delta(seconds)
  }

  /**
   * Adds the other `Delta` from this `Delta` returning a new `Delta`
   *
   * @param  toAdd the other `Delta
   *
   * @returns a new `Delta` with the new value
   */
  add(toAdd: Delta): Delta {
    return new Delta(this._seconds + toAdd._seconds)
  }

  /**
   * Subtracts the other `Delta` from this `Delta` returning a new `Delta`
   *
   * @param  toSubtract the other `Delta
   *
   * @returns a new `Delta` with the new value
   */
  subtract(toSubtract: Delta): Delta {
    return new Delta(this._seconds - toSubtract._seconds)
  }

  /**
   * Multiplies the internal representation of this `Delta` by the given factor
   *
   * @param  factor
   *
   * @returns a new `Delta` with the new value
   */
  multiply(factor: number) {
    return new Delta(this._seconds * factor)
  }

  /**
   * Multiplies the internal representation of this `Delta` by the given factor
   *
   * @param  factor
   *
   * @returns a new `Delta` with the new value
   */

  divide(factor: number) {
    return new Delta(this._seconds / factor)
  }

  /**
   * Checks if this `Delta` and another have the same underlying representation
   * @param  other the other delta
   *
   * @returns whether this and other are equal
   */
  equals(other: Delta): boolean {
    return this._seconds === other._seconds
  }

  /**
   * Provides a escape hatch to get the underlying data
   */
  toSeconds() {
    return this._seconds
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf() {
    throw new Error('Delta can not be used with native arithmetic')
  }
}
