import { maxBy, minBy } from 'lodash'

import { CyclicTime } from './cyclic-time'
import { Delta } from './delta'
import { Duration } from './duration'

/**
 * A EpochTime is lifted up from a CyclicTime.
 * It is a representation of a CyclicTime along with week offset.
 *
 * Unlike CyclicTimes, EpochTimes are ordered and unbounded as
 * they exist outside the representative week.
 */
export class EpochTime {
  /**
   * Constructs a `EpochTime` at the start of the week/cycle
   */
  static readonly epoch = new EpochTime(0)

  constructor(private readonly _seconds: number) {}

  /**
   * Creates a EpochTime using seconds since the week/cycle start
   */
  static fromSeconds(seconds: number) {
    return new EpochTime(seconds)
  }

  /**
   * Can be used to sort EpochTime in ascending order.
   */
  static sortComparator(e1: EpochTime, e2: EpochTime) {
    return e1.toSeconds() - e2.toSeconds()
  }

  /**
   * Returns a CyclicTime representing this EpochTime in week 1
   */
  asCyclicTime() {
    return CyclicTime.fromEpochTime(this)
  }

  /**
   * Makes the EpochTime later by the Duration/Delta provided
   *
   * @returns new later EpochTime
   */
  makeLater(toAdd: Duration | Delta): EpochTime {
    return new EpochTime(this._seconds + toAdd.toSeconds())
  }

  /**
   * Makes the EpochTime earlier by the Duration/Delta provided
   * @returns new delayed EpochTime
   */
  makeEarlier(toSubtract: Duration | Delta): EpochTime {
    return new EpochTime(this._seconds - toSubtract.toSeconds())
  }

  /**
   * Returns if this EpochTime is earlier in time
   * than the `other` EpochTime
   *
   * If they ar equal the result is true
   * @param other another EpochTime
   *
   * @returns whether this is earlier or equal
   */
  isEarlier(other: EpochTime): boolean {
    return this._seconds <= other._seconds
  }

  /**
   * Returns if this EpochTime is earlier in time
   * than the `other` EpochTime
   *
   * If they are equal the result is false
   * @param other another EpochTime
   *
   * @returns whether this is earlier and not equal
   */
  isStrictlyEarlier(other: EpochTime): boolean {
    return this._seconds < other._seconds
  }

  /**
   * Returns if this EpochTime is later in time
   * than the `other` EpochTime
   *
   * If they ar equal the result is true
   * @param other another EpochTime
   *
   * @returns whether this is later or equal
   */
  isLater(other: EpochTime): boolean {
    return this._seconds >= other._seconds
  }

  /**
   * Returns if this EpochTime is later in time
   * than the `other` EpochTime
   *
   * If they are equal the result is false
   * @param other another EpochTime
   *
   * @returns whether this is later and not equal
   */
  isStrictlyLater(other: EpochTime): boolean {
    return this._seconds > other._seconds
  }

  /**
   * Returns if this and another EpochTime are exactly equal
   * @param other another EpochTime
   *
   * @returns whether equal to other
   */
  equals(other: EpochTime): boolean {
    return this._seconds === other._seconds
  }

  /**
   * Returns the earliest `Epochtime` in the list of arguments
   */
  static earliest(...times: EpochTime[]): EpochTime {
    return minBy(times, t => t._seconds)
  }

  /**
   * Returns the latest `Epochtime` in the list of arguments
   */
  static latest(...times: EpochTime[]): EpochTime {
    return maxBy(times, t => t._seconds)
  }

  /**
   * Provides an escape hatch out of EpochTime
   */
  toSeconds(): number {
    return this._seconds
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf() {
    throw new Error('EpochTime can not be used with native arithmetic')
  }
}
