import i18n from 'src/i18n'
import { saveDocument } from 'src/service-design/shared/document/actions/api'
import {
  documentModified,
  documentSaving,
} from 'src/service-design/shared/document/selectors/save'

export const DIRTY_MESSAGE = i18n.t(
  'There are unsaved changes. Are you sure you want to leave?',
)
export const SAVING_MESSAGE = i18n.t(
  'Changes are currently being saved. Are you sure you want to leave before it has finished?',
)
export const setupSave = () => async (dispatch: any, getState: any) => {
  // this sets up a polling save of the specified path in redux (eg "documents.service-design")
  // TODO BOSS-3757 this will need to also save documentRoot="documents2.traingraph"
  dispatch(saveDocument())
  window.onbeforeunload = (ev: BeforeUnloadEvent) => {
    const modified = documentModified(getState())
    if (modified) {
      // eslint-disable-next-line no-param-reassign
      ev.returnValue = DIRTY_MESSAGE
      return DIRTY_MESSAGE
    }

    const saving = documentSaving(getState())
    if (saving) {
      // eslint-disable-next-line no-param-reassign
      ev.returnValue = SAVING_MESSAGE
      return SAVING_MESSAGE
    }
    return null
  }
}
