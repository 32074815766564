import React from 'react'
import { Icon } from 'semantic-ui-react'

import i18n from 'src/i18n'

import './index.css'

export const NotFound = () => (
  <div className="not-found-container">
    <div className="not-found-message">
      <Icon name="find" circular inverted size="big" color="red" />
      {i18n.t("The page you're looking for can't be found")}
    </div>
  </div>
)
