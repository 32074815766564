export interface Theme {
  baseBg: string
  invertedBg0: string
  invertedBg1: string
  baseTextColor: string
  baseInvertedTextColor: string

  white: string
  snowwhite: string
  lightgrey: string
  lightgreyAccent: string
  mediumlightgrey: string
  mediumgrey: string
  mediumgreyAccent: string
  darkmediumgrey: string
  darkgrey: string
  charcoal: string
  lightblue: string
  mediumblue: string
  blue: string
  cyan: string
  darkorange: string
  darkyellow: string
  darkred: string
}

const white = '#fff'
const snowwhite = '#F6F6FC'
const lightgrey = '#E6E6F1'
const lightgreyAccent = '#D2D2E0'

const mediumlightgrey = '#858597'
const mediumgrey = '#535363'
const mediumgreyAccent = '#4a4a5a'

const darkmediumgrey = '#424254'
const darkgrey = '#34343D'
const charcoal = '#232328'

export const lightTheme: Theme = {
  // experimental color-independent names, WIP
  baseBg: white,
  invertedBg0: darkgrey,
  invertedBg1: mediumgrey,
  baseTextColor: charcoal,
  baseInvertedTextColor: snowwhite,

  // just calling colors what they are. Not resilient to theme change.
  white,
  snowwhite,
  lightgrey,
  lightgreyAccent,
  mediumlightgrey,
  mediumgrey,
  mediumgreyAccent,
  darkmediumgrey,
  darkgrey,
  charcoal,
  lightblue: '#bbdeff',
  mediumblue: '#a1b1de',
  blue: '#2185d0',
  cyan: '#03fffc',
  darkyellow: '#981',
  darkorange: '#951',
  darkred: '#a21',
}
