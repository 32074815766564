import classnames from 'classnames'
import { Field, Formik } from 'formik'
import React from 'react'
import { Button } from 'semantic-ui-react'

import { Popup } from 'src/core/ui'

import { StyledInlineForm, StyledInlineField } from './styled'

type InlineFormProps = {
  initialValues?: any
  onSubmit: (value: any) => void
  onClose: () => void
  input: React.ReactNode
  validate: (value: any) => string | undefined
  name: string
  inputProps?: any
}

const InlineForm: React.FC<InlineFormProps> = React.memo(
  ({ initialValues, onClose, input, validate, name, inputProps, onSubmit }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isValid }) => (
        <StyledInlineForm onSubmit={handleSubmit} className="mini">
          <div className="mini ui form">
            <Field
              className="inline field"
              as={input}
              id={name}
              name={name}
              validate={validate}
              {...inputProps}
            />
          </div>
          <Button.Group>
            <Button
              color="green"
              icon="check"
              type="submit"
              disabled={!isValid}
            />
            <Button color="red" icon="close" onClick={onClose} />
          </Button.Group>
        </StyledInlineForm>
      )}
    </Formik>
  ),
)

type InlineFieldProps = {
  active: boolean
  activateField: () => void
  deactivateField: () => void
  value?: any
  rawValueGetter?: (value: any) => any
  read?: (value: any) => any
  input: React.ReactNode
  onSubmit: (instance: any) => void
  validate?: (value: any) => string | undefined
  name: string
  inputProps?: any
  className?: string
  readonly?: boolean
  trigger?: React.ComponentType<{
    className: string
    children: React.ReactChildren
  }>
}

// eslint-disable-next-line react/no-multi-comp
export class InlineField extends React.PureComponent<InlineFieldProps> {
  componentWillUnmount() {
    this.props.deactivateField()
  }

  render() {
    const {
      active,
      activateField,
      deactivateField,
      read = value => value,
      value = null,
      rawValueGetter = null,
      input,
      validate,
      onSubmit,
      name,
      inputProps,
      className = '',
      readonly = false,
      trigger,
    } = this.props

    // This is a really crap way of doing this, but because semantic
    // doesn't support ref forwarding we don't have many options here
    const Trigger = trigger || StyledInlineField

    return (
      <Popup
        on="click"
        open={active}
        onClose={deactivateField}
        onOpen={activateField}
        disabled={readonly}
        content={
          <InlineForm
            onSubmit={onSubmit}
            onClose={deactivateField}
            initialValues={{
              [name]: rawValueGetter ? rawValueGetter(value) : value,
            }}
            input={input}
            validate={validate}
            name={name}
            inputProps={inputProps}
          />
        }
        trigger={
          <Trigger className={classnames('no-styles', className, { readonly })}>
            {read(value)}
          </Trigger>
        }
      />
    )
  }
}
