import React from 'react'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components'

import urljoin from 'url-join'

import { Box } from 'src/core/ui/Box'
import { Stack } from 'src/core/ui/Stack'
import { lightTheme } from 'src/core/ui/themes'
import { Dropdown } from 'src/dated-planning/core/ui/Dropdown'

const StyledStack = styled(Stack)`
  background: ${({ theme }) => theme.invertedBg0};
  color: ${({ theme }) => theme.baseInvertedTextColor};
  font-weight: 500;
`

StyledStack.defaultProps = {
  theme: lightTheme,
}

export type NavItem = {
  name: string
  url: string
}

export type Props = {
  items: NavItem[]
  baseUrl?: string
}

/**
 * A basic navigation component used for top level nav. It provides a simple
 * dropdown menu for navigating top level items.
 *
 * This component is currently very barebones. The future vision may include
 * user/session management, i18n switches, listing the items along the top when
 * space permits, and responsively falling back to a dropdown list view.
 */
export const Nav: React.FC<Props> = ({ items, baseUrl = '' }) => (
  <StyledStack as="nav" direction="horizontal" padding={0.5} space={1}>
    <Dropdown text="Menu">
      <Dropdown.Menu>
        {items.map(item => (
          <Dropdown.Item key={item.name}>
            <NavLink to={urljoin(baseUrl, item.url)}>
              <Dropdown.Item>{item.name}</Dropdown.Item>
            </NavLink>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    <Box>Navis Rail</Box>
  </StyledStack>
)
