import styled from 'styled-components'

export const LeftHandStatus = styled.div``
export const RightHandStatus = styled.div`
  display: flex;
  align-items: center;

  &&& > * {
    margin-right: 2em;
    cursor: pointer;
  }
`
export const StatusBar = styled.footer`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0.5em 3em;
  background: white;
`
