import React from 'react'
import ReactDOM from 'react-dom'
import { Message } from 'semantic-ui-react'

import i18n from 'src/i18n'

import './index.css'
import { INotification } from 'src/service-design/shared/ui/Notifications/reducers'

interface NotificationsProps {
  notifications: INotification[]
  onDismiss: (id: string) => void
}

export const NotificationsInner: React.FC<NotificationsProps> = ({
  notifications,
  onDismiss,
}) =>
  notifications.length > 0 ? ( // Conditionally display otherwise this renders on-top of the navbar
    <div className="notifications">
      {notifications.map(n => (
        <Message
          key={n.id}
          className="message"
          onDismiss={() => onDismiss(n.id)}
          {...{ [n.kind]: true }}
        >
          <Message.Header>{i18n.t(n.header)}</Message.Header>
          {i18n.t(n.text)}
        </Message>
      ))}
    </div>
  ) : null

const bodyEl = document.querySelector('body')

export class Notifications extends React.Component<NotificationsProps> {
  private el: HTMLDivElement

  constructor(props: NotificationsProps) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    bodyEl.appendChild(this.el)
  }

  componentWillUnmount() {
    bodyEl.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(
      <NotificationsInner {...this.props} />,
      this.el,
    )
  }
}
