import React from 'react'
import { useHistory, useLocation, matchPath } from 'react-router'
import { Tab } from 'semantic-ui-react'

export type Pane = {
  menuItem: string
  render: () => React.ReactNode
  path: string
}

export type RoutedTabProps = {
  panes: Pane[]
  className?: string
}

// Custom match hook as we can't use useRouteMatch properly due to needing a loop
const useMatchIndex = (panes: Pane[]) => {
  const location = useLocation()
  return panes.findIndex(pane => matchPath(location.pathname, pane.path))
}

export const RoutedTab: React.FC<RoutedTabProps> = ({ panes, className }) => {
  const matchIndex = useMatchIndex(panes)
  const activeIndex = matchIndex >= 0 ? matchIndex : 0
  const history = useHistory()

  const onTabChange = React.useCallback(
    (e, { activeIndex: index }) => {
      history.push(panes[index].path)
    },
    [panes, history],
  )

  return (
    <Tab
      className={className}
      panes={panes}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    />
  )
}
