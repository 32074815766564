import styled from 'styled-components'

import { lightgrey3 } from 'src/service-design/core/ui/style-constants'

const readViewPadding = '0.3em'

export const StyledInlineField = styled.div`
  && {
    font-family: inherit;
    color: inherit;
    display: inline-block;
    cursor: pointer;
    padding: ${readViewPadding};
    border: 1px solid transparent;
    border-bottom: 1px dashed ${lightgrey3};

    &.readonly {
      cursor: unset;
      border: none;
    }
  }

  &:hover {
    border-radius: 3px;
    border: 1px solid ${lightgrey3};
  }
`
export const StyledInlineForm = styled.form`
  display: flex;
  width: max-content;

  .ui.input input {
    max-width: 5em;
  }

  form {
    display: flex;
  }

  .ui.dropdown.dropdown.dropdown, /* stupid specificity wars */
  .ui.input input,
  .ui.button.icon {
    padding: 0.5em;
  }

  .field {
    display: flex;
  }
`
