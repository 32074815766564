import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body,
  html,
  #root {
    height: 100%;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
`
