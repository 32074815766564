import { SECONDS_PER_WEEK } from 'src/service-design/core/domain/constants'

import { EpochTime } from './epoch-time'

import { normalize as normalizeFn } from '.'

/**
 * In strategic planning, events are planned within a 'representative week'.
 * This representative week repeats indefinitely and can be thought of as a cycle.
 *
 * A `CyclicTime` represents a moment within our cycle or representative week.
 *
 * Any `CyclicTime` can be lifted to a sequence of `EpochTime`s that are precisely one week apart.
 * Any `EpochTime` can be mapped back to CyclicTime by removing the week information from the `EpochTime`
 *
 * It can be projected into any given week offset e.g
 * |  Week 1 |  Week2  |
 * |------>x |------>x |
 *
 */
export class CyclicTime {
  /**
   * Constructs a `CyclicTime` at the start of the week/cycle
   */
  static readonly startOfCycle = new CyclicTime(0)

  constructor(private readonly _seconds: number) {
    if (_seconds < 0 || _seconds > SECONDS_PER_WEEK) {
      throw new Error('Instantiated a value longer than one week')
    }
  }

  /**
   * Creates a CyclicTime from a EpochTime
   */
  static fromEpochTime(epochTime: EpochTime) {
    return new CyclicTime(normalizeFn(epochTime.toSeconds()))
  }

  /**
   * Creates a CyclicTime from a seconds representation
   **/
  static fromSeconds(seconds: number) {
    return new CyclicTime(normalizeFn(seconds))
  }

  /**
   * Provides an escape hatch out of CyclicTime
   */
  toSeconds(): number {
    return this._seconds
  }

  // eslint-disable-next-line class-methods-use-this
  valueOf() {
    throw new Error('CyclicTime can not be used with native arithmetic')
  }

  /**
   * Returns if this and another `CyclicTime` are exactly equal
   * @param other another `CyclicTime`
   *
   * @returns whether equal to other
   */
  equals(other: CyclicTime): boolean {
    return this._seconds === other._seconds
  }
}
