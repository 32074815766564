export class BrokenRelationship {
  public collectionName: string
  public affectedObj: { id: string }
  public relationDefinition: any

  constructor({
    collectionName,
    affectedObj,
    relationDefinition,
  }: {
    collectionName: string
    affectedObj: { id: string }
    relationDefinition: any
  }) {
    this.collectionName = collectionName
    this.affectedObj = affectedObj
    this.relationDefinition = relationDefinition
  }

  get deleteId(): string {
    return this.affectedObj.id
  }

  get foreignKey(): string {
    return this.relationDefinition.foreign
  }

  get missingId(): string {
    // @ts-ignore
    return this.affectedObj[this.foreignKey]
  }

  get message(): string {
    return `Entity with ${this.deleteId} in collection ${this.collectionName} refered to entity in collection ${this.relationDefinition.collection} with id ${this.missingId} in property ${this.foreignKey}`
  }
}
