import tinycolor from 'tinycolor2'

export const white = '#fff'
export const almostwhite = '#f8f8f8'
export const snowwhite = '#f5f5f5'

export const lightgrey0 = '#eee'
export const lightgrey1 = '#ddd'
export const lightgrey2 = '#a8a8a8'
export const lightgrey3 = '#9b9b9b'

export const darkgrey1 = '#4a4a4a'
export const darkgrey2 = '#5c5c5c'
export const darkgrey3 = '#666'
export const darkgrey2Accent = '#575757'

export const greyblue1 = '#45454c'
export const greyblue2 = '#4e515a'
export const greyblue3 = '#6c707f'

export const charcoal = '#2b2b2b'

export const brightGreen = '#51d053'
export const green = '#2c662d'
export const paleGreen = '#cff3cf'
export const paleYellow = '#f9e5a8'
export const yellowBronze = '#ffa922'
export const lightBlue = '#d6dbe7'
export const blue = '#4582d2'
export const darkBlue = '#2c558a'
export const red = '#db2828'
export const pastelRed = '#ff5543'
export const paleRed = '#ffbeb3'
export const paleRedAccent = '#f77'
export const orange = '#f2711c'

export const textBlack = darkgrey1
export const textWhite = snowwhite

export const bgLink = lightBlue
export const bgSelected = blue
export const bgError = red
export const bgWarning = orange
export const bgReliefLine = 'powderblue'
export const bgDragHover = tinycolor(bgSelected).setAlpha(0.2).toRgbString()
export const bgDragHoverError = tinycolor(bgError).setAlpha(0.2).toRgbString()

export const scrollBarWidth = '10px'
