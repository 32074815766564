// @ts-ignore
import Ajv from 'ajv'

export class SchemaError {
  static type = 'Schema Error'

  constructor(public id: any, public message: any) {}

  get type() {
    return (this.constructor as any).type
  }
}

export const validateSchema = (data: any, schema: any) => {
  const ajv = new Ajv({ allErrors: true })
  if (ajv.validate(schema, data)) {
    return []
  }
  return ajv.errors.map(
    (error: any) =>
      new SchemaError(
        error.dataPath,
        `${schema.$id}${error.dataPath} ${error.message}`,
      ),
  )
}
