export const getKeycloakConfig = ():
  | {
      authUrl: string
      cookie: string
      realm: string
      environment: string
    }
  | undefined => (global as any).window?.KEYCLOAK_CONFIG

export const ENVIRONMENT = getKeycloakConfig()?.environment ?? 'local'
